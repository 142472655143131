exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-components-about-link-js": () => import("./../../../src/pages/about/components/AboutLink.js" /* webpackChunkName: "component---src-pages-about-components-about-link-js" */),
  "component---src-pages-about-components-banner-section-js": () => import("./../../../src/pages/about/components/BannerSection.js" /* webpackChunkName: "component---src-pages-about-components-banner-section-js" */),
  "component---src-pages-about-components-collapsible-js": () => import("./../../../src/pages/about/components/Collapsible.js" /* webpackChunkName: "component---src-pages-about-components-collapsible-js" */),
  "component---src-pages-about-components-explore-more-js": () => import("./../../../src/pages/about/components/ExploreMore.js" /* webpackChunkName: "component---src-pages-about-components-explore-more-js" */),
  "component---src-pages-about-components-factory-card-js": () => import("./../../../src/pages/about/components/FactoryCard.js" /* webpackChunkName: "component---src-pages-about-components-factory-card-js" */),
  "component---src-pages-about-components-factory-carousel-js": () => import("./../../../src/pages/about/components/FactoryCarousel.js" /* webpackChunkName: "component---src-pages-about-components-factory-carousel-js" */),
  "component---src-pages-about-components-material-card-js": () => import("./../../../src/pages/about/components/MaterialCard.js" /* webpackChunkName: "component---src-pages-about-components-material-card-js" */),
  "component---src-pages-about-components-scroll-menu-js": () => import("./../../../src/pages/about/components/ScrollMenu.js" /* webpackChunkName: "component---src-pages-about-components-scroll-menu-js" */),
  "component---src-pages-about-components-section-title-js": () => import("./../../../src/pages/about/components/SectionTitle.js" /* webpackChunkName: "component---src-pages-about-components-section-title-js" */),
  "component---src-pages-about-components-side-menu-js": () => import("./../../../src/pages/about/components/SideMenu.js" /* webpackChunkName: "component---src-pages-about-components-side-menu-js" */),
  "component---src-pages-about-our-jewelry-js": () => import("./../../../src/pages/about/OurJewelry.js" /* webpackChunkName: "component---src-pages-about-our-jewelry-js" */),
  "component---src-pages-about-our-journey-js": () => import("./../../../src/pages/about/OurJourney.js" /* webpackChunkName: "component---src-pages-about-our-journey-js" */),
  "component---src-pages-about-our-people-js": () => import("./../../../src/pages/about/OurPeople.js" /* webpackChunkName: "component---src-pages-about-our-people-js" */),
  "component---src-pages-about-our-planet-js": () => import("./../../../src/pages/about/OurPlanet.js" /* webpackChunkName: "component---src-pages-about-our-planet-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-ana-luisa-luxe-cancel-confirm-js": () => import("./../../../src/pages/ana-luisa-luxe/cancel/confirm.js" /* webpackChunkName: "component---src-pages-ana-luisa-luxe-cancel-confirm-js" */),
  "component---src-pages-ana-luisa-luxe-cancel-js": () => import("./../../../src/pages/ana-luisa-luxe/cancel.js" /* webpackChunkName: "component---src-pages-ana-luisa-luxe-cancel-js" */),
  "component---src-pages-ana-luisa-luxe-js": () => import("./../../../src/pages/ana-luisa-luxe.js" /* webpackChunkName: "component---src-pages-ana-luisa-luxe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luxe-js": () => import("./../../../src/pages/luxe.js" /* webpackChunkName: "component---src-pages-luxe-js" */),
  "component---src-pages-luxe-membership-actions-js": () => import("./../../../src/pages/luxe/MembershipActions.js" /* webpackChunkName: "component---src-pages-luxe-membership-actions-js" */),
  "component---src-pages-luxe-price-comparisons-js": () => import("./../../../src/pages/luxe/PriceComparisons.js" /* webpackChunkName: "component---src-pages-luxe-price-comparisons-js" */),
  "component---src-pages-order-history-js": () => import("./../../../src/pages/order-history.js" /* webpackChunkName: "component---src-pages-order-history-js" */),
  "component---src-pages-policies-privacy-policy-js": () => import("./../../../src/pages/policies/privacy-policy.js" /* webpackChunkName: "component---src-pages-policies-privacy-policy-js" */),
  "component---src-pages-policies-refund-policy-js": () => import("./../../../src/pages/policies/refund-policy.js" /* webpackChunkName: "component---src-pages-policies-refund-policy-js" */),
  "component---src-pages-policies-shipping-policy-js": () => import("./../../../src/pages/policies/shipping-policy.js" /* webpackChunkName: "component---src-pages-policies-shipping-policy-js" */),
  "component---src-pages-policies-terms-of-service-js": () => import("./../../../src/pages/policies/terms-of-service.js" /* webpackChunkName: "component---src-pages-policies-terms-of-service-js" */),
  "component---src-templates-blog-articles-index-js": () => import("./../../../src/templates/blog/articles/index.js" /* webpackChunkName: "component---src-templates-blog-articles-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-collection-index-js": () => import("./../../../src/templates/collection/index.js" /* webpackChunkName: "component---src-templates-collection-index-js" */),
  "component---src-templates-how-to-take-care-index-js": () => import("./../../../src/templates/how_to_take_care/index.js" /* webpackChunkName: "component---src-templates-how-to-take-care-index-js" */),
  "component---src-templates-influencer-index-js": () => import("./../../../src/templates/influencer/index.js" /* webpackChunkName: "component---src-templates-influencer-index-js" */),
  "component---src-templates-landing-page-index-js": () => import("./../../../src/templates/landing-page/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-locator-index-js": () => import("./../../../src/templates/locator/index.js" /* webpackChunkName: "component---src-templates-locator-index-js" */),
  "component---src-templates-lp-pdp-index-js": () => import("./../../../src/templates/lp_pdp/index.js" /* webpackChunkName: "component---src-templates-lp-pdp-index-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-return-index-js": () => import("./../../../src/templates/return/index.js" /* webpackChunkName: "component---src-templates-return-index-js" */),
  "component---src-templates-sitemap-index-js": () => import("./../../../src/templates/sitemap/index.js" /* webpackChunkName: "component---src-templates-sitemap-index-js" */),
  "component---src-templates-terms-index-js": () => import("./../../../src/templates/terms/index.js" /* webpackChunkName: "component---src-templates-terms-index-js" */),
  "component---src-templates-track-my-order-index-js": () => import("./../../../src/templates/track-my-order/index.js" /* webpackChunkName: "component---src-templates-track-my-order-index-js" */),
  "component---src-templates-wishlist-index-js": () => import("./../../../src/templates/wishlist/index.js" /* webpackChunkName: "component---src-templates-wishlist-index-js" */)
}

