import { forwardRef, useContext, useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

// eslint-disable-next-line import/no-unresolved
import { useIgTestGroup } from "@intelligems/headless/gatsby";

import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { CartContext } from "../context/CartProvider";
import { WishlistContext } from "../context/wishlistProvider";
import { LoopReturnContext } from "../context/LoopReturnProvider";
import { useAuth } from "../context/AuthProvider";
import { gaCustomEvent, isBrowser } from "../context/helpers";
import useSticky from "../hooks/useSticky";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useIsHydrationComplete from "../hooks/useIsHydrationComplete";

import LocalesPopUp from "./locales/LocalesPopUp";
import { Link } from "./al_components/ALLink";
import LoopReturnSideCart from "./LoopReturnSideCart";
import { ALLogo } from "./ALComponents";
import SideCart from "./SideCart";
import DesktopMenu from "./header/DesktopMenu";
import MobileMenu from "./header/MobileMenu";
import SearchPopup from "./header/SearchPopup";
import AccountOverview from "./AccountOverview";
import AccountLoggedOut from "./AccountLoggedOut";

import { getShopifyStoreByCountry } from "../../locale-shopifies";

import IconAccount from "./icons/svgs/account.svg";
import IconHeart from "./icons/svgs/heart.svg";
import IconCart from "./icons/svgs/cart.svg";
import IconSearch from "./icons/svgs/search.svg";
import IconStore from "./icons/svgs/store.svg";
import IconFaq from "./icons/svgs/faq.svg";
import IconMenu from "./icons/svgs/menu.svg";

import "./header/styles.scss";

/**
 * We use useEffect to get the country code cookie to prevent a strange prod-only error
 * on the FAQ link where the href remains the value at the build time.
 *
 * @returns
 */
function useCurrentShopifyStore() {
  const isoCountryCode = useCurrentCountryCode();
  return getShopifyStoreByCountry(isoCountryCode);
}

const Header = forwardRef(
  ({ searchPopupVisible, setSearchPopupVisible, displayNavigation }, ref) => {
    const { isLoopReturnEnabled } = useContext(LoopReturnContext);
    const wishlistContext = useContext(WishlistContext);
    const { itemsInCart, toggleCart } = useContext(CartContext);

    const { knowledgeBase } = useCurrentShopifyStore();
    const { accountDetails } = useAuth();
    const hydrationComplete = useIsHydrationComplete();
    const offsetHeader = useSticky(true);
    const location = useLocation();
    const dict = useLocalizedSentenceDict();
    const countryCode = useCurrentCountryCode();

    const closeSearchBar = useCallback(() => setSearchPopupVisible(false), [setSearchPopupVisible]);
    const onMenuClick = useCallback((label) => gaCustomEvent("Menu Link", "Click", label), []);
    const handleOnClickFAQ = useCallback(() => onMenuClick("FAQ"), [onMenuClick]);
    const handleOnClickWishlist = useCallback(() => onMenuClick("WISHLIST"), [onMenuClick]);

    // Close the search popup on route change
    useEffect(() => {
      closeSearchBar();
    }, [location]);

    const mobileMenuOpenRef = useRef(null);
    const mobileMenuCloseRef = useRef(null);
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    const handleToggleMenu = useCallback(
      () => setMobileMenuIsOpen(!mobileMenuIsOpen),
      [mobileMenuIsOpen]
    );

    useEffect(() => {
      if (
        mobileMenuIsOpen &&
        mobileMenuIsOpen.current &&
        mobileMenuCloseRef &&
        mobileMenuCloseRef.current
      ) {
        if (mobileMenuIsOpen && mobileMenuIsOpen.current) {
          mobileMenuCloseRef.current.focus();
        } else {
          mobileMenuOpenRef.current.focus();
        }
      }

      // Hide/Show chat when chat is open
      const kustomer = document.getElementById("kustomer-ui-sdk-iframe");
      if (kustomer) {
        if (mobileMenuIsOpen) {
          kustomer.style.display = "none";
        } else {
          kustomer.style.display = "flex";
        }
      }
    }, [mobileMenuIsOpen]);

    const onSearchClick = () => {
      onMenuClick("SEARCH");
      setSearchPopupVisible((v) => !v);
    };

    const tangibleeTest = useIgTestGroup("0cf336d9-a725-48b5-9e97-744b47452ade");
    const tangibleeName = tangibleeTest?.testGroup?.name;

    useEffect(() => {
      if (isBrowser && window.CustomEvent && tangibleeName) {
        const e = new CustomEvent("tangiblee-triggered", { detail: { variation: tangibleeName } });
        console.log("tangiblee-triggered", tangibleeName);
        window.dispatchEvent(e);
        window.tngVariant = tangibleeName;
      }
    }, [tangibleeName]);

    return (
      <header
        ref={ref}
        id="header"
        style={displayNavigation ? offsetHeader : {}}
        className={!displayNavigation ? "header--nav-hidden" : ""}
      >
        {displayNavigation && (
          <nav className="header" role="navigation" aria-label="main navigation">
            {/* NAV LEFT - Mobile */}
            <div className="header_desktop_top">
              <div className="header_left header_left_mobile">
                <button
                  className="header_left_item"
                  onClick={handleToggleMenu}
                  ref={mobileMenuOpenRef}
                  aria-labelledby="menu-button-label"
                  type="button"
                >
                  <span id="menu-button-label" hidden>
                    Menu
                  </span>
                  <IconMenu width="30" stroke="#2D2927" />
                </button>

                <MobileMenu
                  toggleSlideout={handleToggleMenu}
                  isOpen={mobileMenuIsOpen}
                  ref={mobileMenuCloseRef}
                  aria-expanded={mobileMenuIsOpen}
                />

                <button
                  className="header_left_item"
                  onClick={() => setSearchPopupVisible((v) => !v)}
                  aria-labelledby="search-button-label"
                  type="button"
                >
                  <IconSearch width="17" height="17" stroke="#2D2927" fill="none" />
                </button>
              </div>

              {/* NAV LEFT - Desktop */}
              <div className="header_left_desktop">
                <button
                  type="button"
                  className="desktop_menu_header_search"
                  onClick={onSearchClick}
                >
                  <span className="text">Search</span>
                  <IconSearch width="17" height="17" fill="none" stroke="#2D2927" />
                </button>
                <div aria-label="locales">
                  <LocalesPopUp showISO />
                </div>
                <button
                  type="button"
                  className="desktop_menu_header_store"
                  onClick={() => navigate("/us-stores-unitedstates-analuisany")}
                >
                  <IconStore width="17" height="20" fill="none" stroke="#2D2927" />
                  <span className="desktop_menu_header_store_name microtext microtext--bold microtext--uc">
                    STORE
                  </span>
                </button>
              </div>

              {/* LOGO */}
              <div className="header_middle">
                <Link aria-label="ana luisa home" to="/">
                  <ALLogo className="header_middle_logo" />
                </Link>
              </div>

              <div className="header_right">
                {countryCode === "US" && (
                  <div
                    className="header_right_menu header_right_membership"
                    aria-label="account"
                    role="menu"
                    tabIndex={0}
                    type="button"
                  >
                    <p className="microtext microtext--bold microtext-uc">MEMBERSHIP</p>
                    <span className="header_right_membership__dropdown" role="menuitem">
                      <button
                        onClick={() => navigate("/welcome-to-ana-luisa-luxe/")}
                        className="header_right_menu_pages"
                        type="button"
                      >
                        {dict.get("Meet AL Luxe")}
                      </button>
                    </span>
                  </div>
                )}
                <div className="header_right_menu header_right_faq" aria-label="help">
                  <a
                    href={knowledgeBase}
                    target="_blank"
                    onClick={handleOnClickFAQ}
                    rel="noreferrer"
                  >
                    <IconFaq width="24" height="20" fill="none" stroke="#2D2927" />
                  </a>
                </div>

                <div className="header_right_menu header_right_wishlist" aria-label="wish list">
                  <Link to="/pages/wishlist" onClick={handleOnClickWishlist}>
                    <span className="sr-only">{dict.get("Wishlist")}</span>
                    {wishlistContext?.wishlistItems?.length ? (
                      <span className="header_right_wishlist--dot">&nbsp;</span>
                    ) : null}
                    <IconHeart width="22" stroke="#2D2927" />
                  </Link>
                </div>

                <div
                  className="header_right_menu header_right_account"
                  aria-label="account"
                  role="menu"
                  tabIndex={0}
                  type="button"
                >
                  <IconAccount height="20" stroke="#2D2927" />
                  <span className="header_right_account__dropdown" role="menuitem">
                    {hydrationComplete && accountDetails ? (
                      <AccountOverview />
                    ) : (
                      <AccountLoggedOut />
                    )}
                    <Link to="/track-my-order" className="track_order">
                      Track My Order
                    </Link>
                  </span>
                </div>

                <button
                  className={`header_right_menu header_right_bag ${
                    itemsInCart > 0 ? "header_right_bag_active" : ""
                  }`}
                  onClick={toggleCart}
                  aria-label="cart"
                  tabIndex={0}
                  type="button"
                >
                  <IconCart stroke="#2D2927" />
                  {itemsInCart > 0 && <span className="header_right_bag_count">{itemsInCart}</span>}
                </button>
              </div>
            </div>
            <div className="header_desktop_bottom">
              <DesktopMenu onShowMenu={closeSearchBar} />
            </div>
          </nav>
        )}

        {searchPopupVisible ? <SearchPopup closeSearch={closeSearchBar} /> : null}

        {isLoopReturnEnabled ? <LoopReturnSideCart /> : <SideCart />}
      </header>
    );
  }
);

export default withALErrorBoundary({
  name: "Header",
  priority: "P2",
})(Header);
