export const ComponentType = {
  ANNOUNCEMENT_BAR: "ANNOUNCEMENT_BAR",
  BYOB: "BYOB",
  BYOB_ITEMS: "BYOBItems",
  BYOB_PDP: "BYOB_PDP",
  CP: "CP",
  CP_LAZY: "CP_LAZY",
  CP_ADS: "CP_ADS",
  CP_OPTIONAL_PRODUCTS: "CP_OPTIONAL_PRODUCTS",
  FLOATING_ATC: "FLOATING_ATC",
  FLOATING_ATC_ADS: "FLOATING_ATC_ADS",
  FOOTER: "FOOTER",
  HP: "HP",
  LINE_ITEM: "LineItem",
  LUXE: "LUXE",
  PDP: "PDP",
  PDP_ADS: "PDP_ADS",
  PDP_QUICK_UPSELL: "PDP_QUICK_UPSELL",
  PRODUCT_CAROUSEL: "PRODUCT_CAROUSEL",
  QUICKVIEW: "QUICKVIEW",
  REVIEWS: "REVIEWS",
  SEARCH: "Search",
  SIDE_CART: "SIDE_CART",
  SIDE_CART_UPSELL: "SIDE_CART_UPSELL",
  TIER_DISCOUNT_BOTTOM_BANNER: "TIER_DISCOUNT_BOTTOM_BANNER",
  WISHLIST: "WISHLIST",
  MENU: "MENU",
};
