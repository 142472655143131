import PropTypes from "prop-types";

import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { ALButton } from "../ALComponents";
import LockIcon from "../icons/svgs/lock.svg";

function CheckoutButton({ checkoutUrl, isCheckoutDisabled, goToCheckout, isLuxe, saleColors }) {
  const dict = useLocalizedSentenceDict();
  const textColor =
    saleColors &&
    (isLuxe ? saleColors.tiered?.buttonTextColorLuxe : saleColors.tiered?.buttonTextColorGuest);

  const backgroundColor =
    saleColors &&
    (isLuxe
      ? saleColors.tiered?.buttonBackgroundColorLuxe
      : saleColors.tiered?.buttonBackgroundColorGuest);

  if (isCheckoutDisabled) {
    return (
      <div className="sidecart_checkout">
        <ALButton size="large" fullWidth disabled>
          {dict.get("SECURE CHECKOUT")}
          <LockIcon width="17" height="21" fill="none" stroke="white" className="ml-20" />
        </ALButton>
        <p className="sidecart__message--disabled">
          You&apos;re nearly there! <span className="bold">Add another item</span> to check out.
        </p>
      </div>
    );
  }

  const styleButtonIcon = `
    .checkout_lock_icon {
      stroke: ${textColor};
    }
    .checkout__button:hover > .checkout_container > .checkout_title_icon > .checkout_lock_icon:hover {
      stroke: ${backgroundColor};
    }
  `;
  return (
    <div className="sidecart_checkout">
      <ALButton
        variant={isLuxe ? "black" : "primary"}
        size="large"
        fullWidth
        className="checkout__button"
        onClick={() => goToCheckout(checkoutUrl)}
        buttonBackgroundColor={
          isLuxe && saleColors
            ? saleColors.tiered?.buttonBackgroundColorLuxe
            : saleColors.tiered?.buttonBackgroundColorGuest
        }
        buttonTextColor={
          isLuxe && saleColors
            ? saleColors.tiered?.buttonTextColorLuxe
            : saleColors.tiered?.buttonTextColorGuest
        }
      >
        <div className="checkout_container">
          <div className="checkout_title_icon">{dict.get("SECURE CHECKOUT")}</div>
        </div>
      </ALButton>
      <div dangerouslySetInnerHTML={{ __html: `<style>${styleButtonIcon}</style>` }} />
    </div>
  );
}

CheckoutButton.propTypes = {
  checkoutUrl: PropTypes.string,
  isCheckoutDisabled: PropTypes.bool,
  goToCheckout: PropTypes.func,
  isLuxe: PropTypes.bool,
};

export default CheckoutButton;
