import { useContext, useMemo, useState, createContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";

export const AnnouncementBarContext = createContext({});

export function useAnnouncementBarPopup() {
  return useContext(AnnouncementBarContext);
}
export default function AnnouncementBarProvider({ children }) {
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(location.search.includes("offer_details=true"));

  const abData = useMemo(
    () => ({
      popupOpen,
      setPopupOpen,
    }),
    [popupOpen, setPopupOpen]
  );

  return (
    <AnnouncementBarContext.Provider value={abData}>{children}</AnnouncementBarContext.Provider>
  );
}

AnnouncementBarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
