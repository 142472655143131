import { useMemo } from "react";
import PropTypes from "prop-types";

import { useCurrentCountryCode } from "../../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";

import * as Styles from "./shippingDeadlines/styles.module.scss";

function ShippingDeadlines({ popup }) {
  const countryCode = useCurrentCountryCode();
  const dict = useLocalizedSentenceDict();

  const deadlineCountry = useMemo(() => {
    if (countryCode && popup?.deadlines?.internal?.content) {
      let jsonDeadline = JSON.parse(popup.deadlines.internal.content)[countryCode];
      if (!jsonDeadline) {
        jsonDeadline = JSON.parse(popup.deadlines.internal.content).DEFAULT;
      }
      if (jsonDeadline) {
        return jsonDeadline;
      }
    }
    return null;
  }, [countryCode, popup.deadlines]);

  if (!deadlineCountry) {
    return null;
  }

  return (
    <>
      <p className={`al_p ${Styles.popup_title_deadline}`}>{dict.get("Order deadlines")}:</p>
      <div className={Styles.popup_deadlines_mobile}>
        {deadlineCountry && deadlineCountry.standard && (
          <>
            <p className={`al_p al_p--bold ${Styles.popup_title_shipping_type}`}>
              {dict.get("Standard")}*
            </p>
            <p className={`al_p ${Styles.popup_title_shipping_date}`}>{deadlineCountry.standard}</p>
          </>
        )}

        {deadlineCountry && deadlineCountry.express && (
          <>
            <p className={`al_p al_p--bold ${Styles.popup_title_shipping_type}`}>
              {dict.get("Express")}*
            </p>
            <p className={`al_p ${Styles.popup_title_shipping_date}`}>{deadlineCountry.express}</p>
          </>
        )}
      </div>
      <div className={Styles.popup_deadlines_desktop}>
        {deadlineCountry && deadlineCountry.standard ? (
          <div className={`${Styles.popup_title_deadline_c}`}>
            <p className={`al_p al_p--bold ${Styles.popup_title_shipping_type}`}>
              {dict.get("Standard")}*
            </p>
            <p className={`al_p ${Styles.popup_title_shipping_date}`}>{deadlineCountry.standard}</p>
          </div>
        ) : null}

        {deadlineCountry && deadlineCountry.express && (
          <div className={Styles.popup_title_deadline_c}>
            <p className={`al_p al_p--bold ${Styles.popup_title_shipping_type}`}>
              {dict.get("Express")}*
            </p>
            <p className={`al_p ${Styles.popup_title_shipping_date}`}>{deadlineCountry.express}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default ShippingDeadlines;

ShippingDeadlines.propTypes = {
  popup: PropTypes.shape({
    deadlines: PropTypes.shape({
      internal: PropTypes.shape({
        content: PropTypes.string,
      }),
    }),
  }),
};
