/**
 * Get discounted items from cart lines based on discount match type and tags.
 * @param {Object} discount
 * @param {Object[]} cartLines
 * @returns {Object[]} filtered cart lines
 * @example
 * const giftDiscountInfo = discountsInfo.find(
 *   ({ type }) => type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE
 * );
 * const giftDiscountedItems = getDiscountedItems(giftDiscountInfo, lineItems);
 */
export default function getDiscountedItems(discount, cartLines) {
  if (!discount || !cartLines?.length) return [];

  const { matchType, matchTags = [] } = discount;

  return cartLines.filter((line) => {
    const productTags = line.variant?.product?.tags || [];
    const attributes = line.attributes || [];
    const isFreeGift = attributes.some(
      ({ key, value }) => key === "_free_gift" && value === "true"
    );

    if (isFreeGift) return false; // filter out free gift items whatever the discount type
    if (matchType === "include") {
      return productTags.some((tag) => matchTags.includes(tag));
    }
    if (matchType === "exclude") {
      return !productTags.some((tag) => matchTags.includes(tag));
    }
    return false;
  });
}
