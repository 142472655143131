// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--a1a7f";
export var bold = "styles-module--bold--b1ec1";
export var center = "styles-module--center--5a89e";
export var h1 = "styles-module--h1--2bbd3";
export var h1Bold = "styles-module--h1--bold--e6b3e";
export var h1Uc = "styles-module--h1--uc--0196a";
export var h2 = "styles-module--h2--ca014";
export var h2Bold = "styles-module--h2--bold--a68a3";
export var h2Uc = "styles-module--h2--uc--e0654";
export var h3 = "styles-module--h3--e11da";
export var h3Bold = "styles-module--h3--bold--c06a5";
export var h3Uc = "styles-module--h3--uc--37297";
export var h4 = "styles-module--h4--91ad8";
export var h4Bold = "styles-module--h4--bold--81d9f";
export var h4Uc = "styles-module--h4--uc--4ded8";
export var linkUnderline = "styles-module--link--underline--ddf6d";
export var microtext = "styles-module--microtext--85d48";
export var microtextBold = "styles-module--microtext--bold--d428b";
export var microtextUc = "styles-module--microtext--uc--2f804";
export var nanotext = "styles-module--nanotext--ed4f4";
export var price = "styles-module--price--b9053";
export var price__label = "styles-module--price__label--9c474";
export var price__value = "styles-module--price__value--293ad";
export var price__value__luxe = "styles-module--price__value__luxe--ebfb3";
export var price__value__luxe__icon = "styles-module--price__value__luxe__icon--75cf7";
export var price__value__luxe__price = "styles-module--price__value__luxe__price--fac58";
export var strikethrough = "styles-module--strikethrough--07b66";
export var strikethroughBold = "styles-module--strikethrough--bold--b1059";
export var strikethroughLg = "styles-module--strikethrough--lg--b4f15";
export var strikethroughSm = "styles-module--strikethrough--sm--a86bf";
export var subtext = "styles-module--subtext--a3cff";
export var subtextBold = "styles-module--subtext--bold--41e6d";
export var subtextUc = "styles-module--subtext--uc--a11db";
export var text = "styles-module--text--1c367";
export var textBold = "styles-module--text--bold--a1de1";
export var textUc = "styles-module--text--uc--9c300";
export var titleL = "styles-module--title-l--bc866";
export var titleM = "styles-module--title-m--0ff4d";
export var titleS = "styles-module--title-s--c7233";
export var titleXl = "styles-module--title-xl--bb604";
export var titleXs = "styles-module--title-xs--525e2";
export var titleXxs = "styles-module--title-xxs--ed491";
export var uc = "styles-module--uc--0398a";
export var underline = "styles-module--underline--99358";