// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--feef9";
export var bold = "styles-module--bold--75f1d";
export var center = "styles-module--center--0c339";
export var h1 = "styles-module--h1--37bfa";
export var h1Bold = "styles-module--h1--bold--c220a";
export var h1Uc = "styles-module--h1--uc--c55b6";
export var h2 = "styles-module--h2--4783b";
export var h2Bold = "styles-module--h2--bold--b8349";
export var h2Uc = "styles-module--h2--uc--51d9e";
export var h3 = "styles-module--h3--343a2";
export var h3Bold = "styles-module--h3--bold--f3362";
export var h3Uc = "styles-module--h3--uc--d7122";
export var h4 = "styles-module--h4--0bc76";
export var h4Bold = "styles-module--h4--bold--49638";
export var h4Uc = "styles-module--h4--uc--ceda5";
export var linkUnderline = "styles-module--link--underline--6b440";
export var microtext = "styles-module--microtext--93868";
export var microtextBold = "styles-module--microtext--bold--b19de";
export var microtextUc = "styles-module--microtext--uc--e27d1";
export var nanotext = "styles-module--nanotext--cfcdc";
export var section = "styles-module--section--9b839";
export var section__text = "styles-module--section__text--4a607";
export var section_luxe = "styles-module--section_luxe--e2a19";
export var strikethrough = "styles-module--strikethrough--9a9d8";
export var strikethroughBold = "styles-module--strikethrough--bold--00680";
export var strikethroughLg = "styles-module--strikethrough--lg--907f3";
export var strikethroughSm = "styles-module--strikethrough--sm--4913e";
export var subtext = "styles-module--subtext--063c3";
export var subtextBold = "styles-module--subtext--bold--bdf7a";
export var subtextUc = "styles-module--subtext--uc--0a180";
export var text = "styles-module--text--6d5aa";
export var textBold = "styles-module--text--bold--a8c64";
export var textUc = "styles-module--text--uc--5d600";
export var titleL = "styles-module--title-l--a9dad";
export var titleM = "styles-module--title-m--bc342";
export var titleS = "styles-module--title-s--39940";
export var titleXl = "styles-module--title-xl--a6d70";
export var titleXs = "styles-module--title-xs--4375e";
export var titleXxs = "styles-module--title-xxs--c7350";
export var uc = "styles-module--uc--6a50b";
export var underline = "styles-module--underline--1632e";