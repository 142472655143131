import { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { isOutOfStock } from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode, usePriceLocalInfo } from "../../hooks/usePrices";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import ProductCollectionContent from "./ProductCollectionContent";
import PriceContainer from "../PriceContainer";
import ProductMention from "../ProductMention";
import { ALSkeleton } from "../ALComponents";
import { ComponentType } from "../../constants/ComponentType";
import { HandleType } from "../../constants/HandleType";
import "./productCollection.scss";
import { LoopReturnContext } from "../../context/LoopReturnProvider";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

function ProductCollectionContentPlaceholder({ product, prices, componentType }) {
  const discountsInfo = useDiscountsInfo();
  const { isMobile } = useDeviceDetect();
  const countryCode = useCurrentCountryCode();
  const contentfulProduct = product.contentful.node;
  const isSoldOut =
    isOutOfStock(product.node, countryCode) && product.node.handle !== HandleType.GIFT_CARD;

  const price = prices?.find((p) => p?.node?.country === countryCode)?.node;
  const productPrices = usePriceLocalInfo({
    handle: product.node.handle,
    price,
    tags: { [product.node.handle]: product.node.tags },
    shouldUseDiscount: [ComponentType.BYOB, ComponentType.BYOB_PDP].indexOf(componentType) === -1,
  });

  const productMentionData = useMemo(
    () => ({
      id: product.node.id,
      tags: product.node.tags,
      isPreOrder: product.contentful.node.isPreOrder,
      preOrderShippingDate: product.contentful.node.preOrderShippingDate,
      type: product.node.productType,
    }),
    [product]
  );

  return (
    <div className="product_collection product_collection_placeholder">
      <a href={`/products/${contentfulProduct.handle}`}>
        <div className="product_collection_placeholder__image_wrapper">
          <div className="product_collection_placeholder__image">
            <ALSkeleton />
          </div>
        </div>
        <div className="product_collection_details">
          {!isSoldOut && (
            <ProductMention
              prices={productPrices}
              product={productMentionData}
              discountsInfo={discountsInfo}
              globalProductMention={discountsInfo?.[0]?.globalProductText}
              componentType={componentType}
            />
          )}
          <div className="product_collection_details__wrapper">
            <div className="pr-5">
              <h2 className="m-0 subtext subtext--bold subtext--uc black">
                {contentfulProduct.title.split(" - ")[1]}
              </h2>
              <h3 className="product_collection_details_subtext microtext dark-grey">
                {contentfulProduct.title.split(" - ")[0]}
              </h3>
            </div>
            {!isSoldOut && (
              <PriceContainer
                componentType={componentType}
                productHandle={product.node.handle}
                tags={product.node.tags}
                prices={productPrices}
                pricesLoading={false}
                productId={product.node.shopifyId}
              />
            )}
          </div>
        </div>
      </a>
      {contentfulProduct?.productAlternatives?.alternatives.map((alternative) => {
        if (alternative?.handle === contentfulProduct?.handle) return null;
        return (
          <a
            className="microtext dark-grey"
            key={`${contentfulProduct?.handle}_alternative_${alternative.handle}`}
            href={`/products/${alternative?.handle}`}
          >
            {alternative.attributes.map((a) => {
              const attr = a.split("-");
              return `${attr[0].trim()}: ${attr[attr.length - 1].trim()} `;
            })}
          </a>
        );
      })}

      {componentType === ComponentType.BYOB ||
        (isMobile && ( // BYOB CTA
          <div className="product_collection_placeholder__content__cta">
            <ALSkeleton />
          </div>
        ))}
    </div>
  );
}

function ProductCollection(props) {
  const { collectionHandle, product, componentType, orderProduct, shouldOrderProducts, prices } =
    props;
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);
  const [selectedAlternativeHandle, setSelectedAlternativeHandle] = useState(product.node.handle);

  const activeAlternative =
    (product.contentful.node?.productAlternatives?.alternatives || []).find(
      (p) => p.node?.handle === selectedAlternativeHandle
    ) || product;

  const isLazyloadEnabled = componentType !== ComponentType.SEARCH;

  // Do not display engravable products to customers placing a replacement order
  if (isLoopReturnEnabled && product.contentful.node.engravable?.isEngravable) {
    return null;
  }

  const handleProductClick = () => {
    const scrollPosition = window.scrollY;
    window.history.pushState(
      {
        ...window.history.state,
        scrollPosition,
      },
      document.title
    );
  };

  useEffect(() => {
    const scrollPosition = window.history.state?.scrollPosition || 0;
    window.scrollTo(0, scrollPosition);
  }, []);

  return (
    <div
      className={`pc_${product?.node?.handle} ${
        shouldOrderProducts ? `order-${orderProduct} ${collectionHandle}-order-${orderProduct}` : ""
      } product_collection-${componentType?.toLowerCase()}-wrapper`}
      onClick={handleProductClick}
    >
      {isLazyloadEnabled ? (
        <LazyLoad
          once
          placeholder={
            <ProductCollectionContentPlaceholder
              product={activeAlternative}
              prices={prices}
              componentType={componentType}
            />
          }
        >
          <ProductCollectionContent
            {...{
              ...props,
              product: activeAlternative,
              setActiveAlternative: setSelectedAlternativeHandle,
            }}
          />
        </LazyLoad>
      ) : (
        <ProductCollectionContent
          {...{
            ...props,
            product: activeAlternative,
            setActiveAlternative: setSelectedAlternativeHandle,
          }}
        />
      )}
    </div>
  );
}

ProductCollection.propTypes = {
  collectionHandle: PropTypes.string,
  collectionTitle: PropTypes.string,
  product: PropTypes.shape({
    contentful: PropTypes.shape({
      node: PropTypes.shape({
        handle: PropTypes.string.isRequired,
        productAlternatives: PropTypes.shape({
          alternatives: PropTypes.array,
        }),
      }).isRequired,
    }),
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      shopifyId: PropTypes.string.isRequired,
      productType: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          availableForSale: PropTypes.bool.isRequired,
          inventoryPolicy: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }),
  componentType: PropTypes.oneOf([
    ComponentType.BYOB,
    ComponentType.BYOB_PDP,
    ComponentType.SEARCH,
    ComponentType.WISHLIST,
    ComponentType.CP,
    ComponentType.CP_ADS,
  ]).isRequired,
  cpPosition: PropTypes.number.isRequired,
  orderProduct: PropTypes.number,
  shouldOrderProducts: PropTypes.bool,
  searchObject: PropTypes.shape({
    isSearch: PropTypes.bool,
    index: PropTypes.string,
    queryID: PropTypes.string,
    objectID: PropTypes.string,
  }),
  className: PropTypes.string,
  prices: PropTypes.array,
};

export default withALErrorBoundary({
  name: "ProductCollection",
  priority: "P2",
})(ProductCollection);
