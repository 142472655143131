import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import axios from "axios";
import produce from "immer";
import { useLocation } from "@reach/router";

import StoreContext, { defaultStoreContext } from "./store";
import { GeoContext } from "./GeoProvider";
import {
  isBrowser,
  getFreeShippingThresholdFromCountry,
  getShippingFeeFromCountry,
  decodeProductId,
  getAllUrlParams,
  removeQueryParam,
} from "./helpers";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useTracking } from "./Tracking";
import { getAlgoliaIndexBaseKey } from "../../locale-shopifies";
import { useCurrentLang } from "../hooks/useLocalizedCartInfo";
import useDiscountsInfo from "../hooks/useDiscountsInfo";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

function Provider({
  children,
  recommendationExponea,
  setDisplayQuickAccess,
  setDisplayAB,
  displayAB,
  setDisplayNavigation,
  displayNavigation,
  setOptionalProductsData,
}) {
  const location = useLocation();
  const { sendReport } = useALError();
  const discountsInfo = useDiscountsInfo();
  const { gePriceDetails, isLoadingCountry } = useContext(GeoContext);

  const [store, updateStore] = useState(defaultStoreContext);
  const countryCode = gePriceDetails?.CountryCode;
  const getlocalStorage = (value) => {
    try {
      if (!isBrowser) return "";
      return JSON.parse(localStorage.getItem(value));
    } catch (e) {
      sendReport(e, { name: "Provider", priority: "P1" });
      return "";
    }
  };
  const lang = useCurrentLang();
  const { trackQuickViewClick } = useTracking();

  const { shippingFees, searchIndex } = useMemo(() => {
    if (isLoadingCountry || !countryCode) return {};
    const shippingFees = {
      shippingPrice: getShippingFeeFromCountry(gePriceDetails),
      freeShippingThreshold: getFreeShippingThresholdFromCountry(gePriceDetails),
    };
    const algoliaIndex = getAlgoliaIndexBaseKey(countryCode, lang);

    return { shippingFees, searchIndex: `products-${algoliaIndex}` };
  }, [countryCode, gePriceDetails, lang, isLoadingCountry]);

  useMemo(() => {
    const queryParams = getAllUrlParams(location.href);
    if (queryParams && queryParams.tag && queryParams.cid && queryParams.email) {
      // Set new date format: yyyy-mm-dd
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const data = JSON.stringify({
        eventName: "tag_shopify",
        email: `${queryParams.email.replaceAll(" ", "+")}`,
        data: {
          tag: queryParams.tag,
          date: `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`,
          cid: queryParams.cid,
        },
      });

      const config = {
        method: "GET",
        url: "/.netlify/functions/webhook-bloomreach",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      };

      axios.request(config).catch((error) => {
        sendReport(error, { name: "Provider", priority: "P2" });
      });

      removeQueryParam("tag");
      removeQueryParam("cid");
      removeQueryParam("email");
    }
  }, [location, sendReport]);

  const giftDiscount = discountsInfo?.find(
    (discount) => discount.type === DISCOUNT_TYPES.FREE_GIFT
  );
  useEffect(() => {
    if (isLoadingCountry) return;

    // TODO:
    // - Add the discount logic to the discount provider
    // - Then remove this useEffect
    if (giftDiscount?.freegift && !giftDiscount?.freegift?.data) {
      const giftHandle = giftDiscount?.freegift?.product.handle;
      axios
        .get(
          `/.netlify/functions/get-product?handles=${giftHandle}&countryCode=${countryCode}&length=short`
        )
        .then((r) => {
          if (r.status === 200) {
            updateStore((state) => ({
              ...state,
              discountInfo: {
                ...state.discountInfo,
                freegift: {
                  ...state.giftDiscount.freegift,
                  data: r.data.products[0],
                },
              },
            }));
          }
        })
        .catch((err) => {
          console.error("error free gift:");
          console.error(err);
          sendReport(err, { name: "Provider", priority: "P1" });
        });
    }
  }, [countryCode, isLoadingCountry, sendReport, giftDiscount?.freegift]);

  const addTrackCartProduct = (trackingCartProduct) => {
    updateStore(
      produce((draftStore) => {
        // We track only one product added by origin
        const isAlreadyTracked = draftStore.trackCartProduct.find(
          (productTracked) =>
            productTracked.productId === trackingCartProduct.productId &&
            productTracked.origin === trackingCartProduct.origin
        );
        if (!isAlreadyTracked) {
          draftStore.trackCartProduct.push(trackingCartProduct);
        }
      })
    );
  };

  const updateSalesColor = (data) => {
    updateStore((store) => ({
      ...store,
      saleColors: data,
    }));
  };

  const closeQuickView = () => {
    updateStore((store) => ({
      ...store,
      quickViewProductHandle: null,
      quickViewLinkToPDP: "",
    }));
  };

  const openQuickView = useCallback(
    ({ linkToPDP, collectionHandle, cpPosition, product }) => {
      updateStore((store) => ({
        ...store,
        quickViewProductHandle: product.handle,
        quickViewLinkToPDP: linkToPDP,
      }));

      trackQuickViewClick({
        collectionHandle,
        cpPosition,
        product,
      });
    },
    [trackQuickViewClick]
  );

  const setBYOBOptionalItemPopup = (open) => {
    updateStore((store) => ({
      ...store,
      isBYOBOptionalItemPopupOpen: open,
    }));
  };

  const fullStore = useMemo(
    () => ({
      ...store,
      shippingFees,
      searchIndex,
      recommendationExponea: recommendationExponea?.edges || null,
    }),
    [store, shippingFees, searchIndex, recommendationExponea?.edges]
  );

  const customerAccessToken = getlocalStorage("customerAccessToken");
  const providerData = useMemo(
    () => ({
      store: fullStore,
      customerAccessToken,
      setDisplayQuickAccess,
      addTrackCartProduct,
      decodeProductId,
      updateSalesColor,
      openQuickView,
      closeQuickView,
      setBYOBOptionalItemPopup,
      displayAB,
      setDisplayAB,
      setDisplayNavigation,
      displayNavigation,
      setOptionalProductsData,
    }),
    [
      fullStore,
      customerAccessToken,
      setDisplayQuickAccess,
      openQuickView,
      displayAB,
      setDisplayAB,
      setDisplayNavigation,
      displayNavigation,
      setOptionalProductsData,
    ]
  );

  return <StoreContext.Provider value={providerData}>{children}</StoreContext.Provider>;
}

export default Provider;
