import DISCOUNT_TYPES from "../constants/DiscountTypes";
import { HandleType } from "../constants/HandleType";

export function getDiscountText(discountInfo) {
  switch (discountInfo?.type) {
    case DISCOUNT_TYPES.SITEWIDE_PERCENT:
    case DISCOUNT_TYPES.BOGO:
      return `${discountInfo.value}%`;
    case DISCOUNT_TYPES.QUANTITY_PERCENT:
    case DISCOUNT_TYPES.AMOUNT_PERCENT:
    case DISCOUNT_TYPES.AMOUNT_VALUE:
      const value = discountInfo.discounts[discountInfo.discounts.length - 1];
      return discountInfo.type.includes("dollar") ? `$${value[1]}` : `${value[1]}%`;
    default:
      return `${discountInfo.value}%`;
  }
}

export function findDiscountInThresholds(discountThresholds, value) {
  let idx = discountThresholds.length - 1;
  for (let i = 0; i < discountThresholds.length; i++) {
    const [threshold] = discountThresholds[i];
    if (threshold > value) {
      idx = i - 1;
      break;
    }
  }
  const discountValue = idx >= 0 ? discountThresholds[idx][1] : 0;
  return discountValue;
}

/**
 *
 * @returns an object with the following structure:
 * {
 *  lines: LineItem[],
 *  fullAmount: number,
 *  finalAmount: number,
 *  discountCompareAtPrice: number,
 *  discountLuxe: number,
 *  discountTieredOffer: number,
 *  discountPercentOff: number,
 *  discountFull: number,
 *  freegiftLineItemId: string | null
 * }
 *
 * */
export function applyCartDiscounts(lineItems, discountsInfo) {
  if (!discountsInfo || !discountsInfo.length || !lineItems)
    return { lines: [], fullAmount: 0, finalAmount: 0 };

  let fullAmount = 0;
  let finalAmount = 0;
  let finalAmountLux = 0;
  let discountCompareAtPrice = 0; // Compare At Price
  let discountLuxe = 0;
  let discountTieredOffer = 0;
  let discountTieredOfferLuxe = 0;
  let discountPercentOff = 0;
  let discountPercentOffLuxe = 0;
  let discountFreeGift = 0;
  let discountFull = 0; // Should we add that? Or keep fullAmount - finalAmount
  const EXCLUDED_FROM_TOTAL = [
    HandleType.ORDER_PROTECTION, // Navidium
    HandleType.SUBSCRIPTION, // Subscription
  ];

  const discountedLineItems = lineItems;

  fullAmount += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.full_price || 0);
    }
    return c;
  }, 0);
  finalAmount += discountedLineItems.reduce((c, itm) => {
    if (
      !EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle) &&
      !(itm?.attributes || []).some(({ key, value }) => key === "_free_gift" && value === "true")
    ) {
      return c + (itm.variant.final_price || 0);
    }
    return c;
  }, 0);
  finalAmountLux += discountedLineItems.reduce((c, itm) => {
    if (
      !EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle) &&
      !(itm?.attributes || []).some(({ key, value }) => key === "_free_gift" && value === "true")
    ) {
      return c + (itm.variant.final_price_luxe || 0);
    }
    return c;
  }, 0);
  discountCompareAtPrice += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.discount_compare_at_price || 0);
    }
    return c;
  }, 0);
  discountTieredOffer += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.discount_tiered_offer || 0);
    }
    return c;
  }, 0);
  discountTieredOfferLuxe += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.discount_tiered_offer_luxe || 0);
    }
    return c;
  }, 0);
  discountPercentOff += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.discount_percent_off || 0);
    }
    return c;
  }, 0);
  discountPercentOffLuxe += discountedLineItems.reduce((c, itm) => {
    if (!EXCLUDED_FROM_TOTAL.includes(itm.variant.product.handle)) {
      return c + (itm.variant.discount_percent_off_luxe || 0);
    }
    return c;
  }, 0);
  discountFreeGift += discountedLineItems.reduce((c, itm) => {
    if (
      (itm?.attributes || []).some(({ key, value }) => key === "_free_gift" && value === "true")
    ) {
      return c + itm.variant.full_price;
    }
    return c;
  }, 0);
  discountLuxe =
    discountTieredOfferLuxe -
    discountTieredOffer +
    (discountPercentOffLuxe ? discountPercentOffLuxe - discountPercentOff : 0);
  discountFull = discountCompareAtPrice + discountLuxe + discountTieredOffer + discountFreeGift;

  const tierDiscountInfo = discountsInfo.find(
    ({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  // TODO: Refactor with getDiscountedItems
  const eligibleItems = lineItems.reduce((acc, itm) => {
    if (!tierDiscountInfo) return acc;
    // filter out free gift items
    if (
      (itm?.attributes || []).some(({ key, value }) => key === "_free_gift" && value === "true")
    ) {
      return acc;
    }
    // filter items based on:
    // inclusion tags (include): items that don't have any of the inclusion tags are not eligible
    // exclusion tags (exclude): items that have at least one of the exclusion tags are not eligible
    if (tierDiscountInfo.matchType === "exclude") {
      return itm.variant.product.tags.some((tag) => tierDiscountInfo.matchTags.includes(tag))
        ? acc
        : acc + itm.quantity;
    }
    if (tierDiscountInfo.matchType === "include") {
      return itm.variant.product.tags.some((tag) => tierDiscountInfo.matchTags.includes(tag))
        ? acc + itm.quantity
        : acc;
    }
    return acc;
  }, 0);

  return {
    lines: discountedLineItems,
    nonBannedItemQuantity: eligibleItems,
    fullAmount,
    finalAmount,
    finalAmountLux,
    discountFreeGift,
    discountCompareAtPrice,
    discountLuxe,
    discountTieredOffer,
    discountTieredOfferLuxe,
    discountPercentOff,
    discountPercentOffLuxe,
    discountFull,
    freegiftLineItemId: lineItems.find((itm) =>
      (itm?.attributes || []).some(({ key, value }) => key === "_free_gift" && value === "true")
    )?.id,
  };
}
