// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--1a710";
export var bold = "styles-module--bold--e140e";
export var center = "styles-module--center--1dab3";
export var h1 = "styles-module--h1--5f3a6";
export var h1Bold = "styles-module--h1--bold--19688";
export var h1Uc = "styles-module--h1--uc--e1c1b";
export var h2 = "styles-module--h2--a88db";
export var h2Bold = "styles-module--h2--bold--a7413";
export var h2Uc = "styles-module--h2--uc--7b44e";
export var h3 = "styles-module--h3--331db";
export var h3Bold = "styles-module--h3--bold--3a462";
export var h3Uc = "styles-module--h3--uc--4d9c0";
export var h4 = "styles-module--h4--94877";
export var h4Bold = "styles-module--h4--bold--62710";
export var h4Uc = "styles-module--h4--uc--4ad4d";
export var linkUnderline = "styles-module--link--underline--7828a";
export var microtext = "styles-module--microtext--cf711";
export var microtextBold = "styles-module--microtext--bold--9b273";
export var microtextUc = "styles-module--microtext--uc--84ad4";
export var nanotext = "styles-module--nanotext--5a88d";
export var pdp_emergency = "styles-module--pdp_emergency--62d2e";
export var pdp_emergency_circle = "styles-module--pdp_emergency_circle--7b39b";
export var pdp_emergency_dot = "styles-module--pdp_emergency_dot--7697a";
export var pdp_emergency_pulse = "styles-module--pdp_emergency_pulse--23b1c";
export var pdp_emergency_text = "styles-module--pdp_emergency_text--142a0";
export var pulse = "styles-module--pulse--fb073";
export var strikethrough = "styles-module--strikethrough--41008";
export var strikethroughBold = "styles-module--strikethrough--bold--1f164";
export var strikethroughLg = "styles-module--strikethrough--lg--08c03";
export var strikethroughSm = "styles-module--strikethrough--sm--92b91";
export var subtext = "styles-module--subtext--9a380";
export var subtextBold = "styles-module--subtext--bold--15ff6";
export var subtextUc = "styles-module--subtext--uc--da213";
export var text = "styles-module--text--d0d1c";
export var textBold = "styles-module--text--bold--13164";
export var textUc = "styles-module--text--uc--33761";
export var titleL = "styles-module--title-l--dd22e";
export var titleM = "styles-module--title-m--6cfdd";
export var titleS = "styles-module--title-s--67d55";
export var titleXl = "styles-module--title-xl--13418";
export var titleXs = "styles-module--title-xs--f0a9c";
export var titleXxs = "styles-module--title-xxs--c57f9";
export var uc = "styles-module--uc--49e86";
export var underline = "styles-module--underline--1941f";