import { useStaticQuery, graphql } from "gatsby";
import { useLocalizedCartInfo } from "./useLocalizedCartInfo";
import useRecommendation from "./useRecommendation";
import useDiscountsInfo from "./useDiscountsInfo";

export default function useUpSellProducts() {
  const discountsInfo = useDiscountsInfo();
  const cartInfo = useLocalizedCartInfo();
  const handlesToExclude = new Set();
  const arrUpsellProducts = [];

  cartInfo.lines.forEach((line) => {
    handlesToExclude.add(line.variant.product.handle);
  });

  const bestSellersData = useStaticQuery(graphql`
    query BestSellersQuery {
      allShopifyCollection(filter: { handle: { eq: "bestsellers" } }, limit: 1) {
        edges {
          node {
            handle
            products {
              handle
              shopifyId
              tags
              variants {
                inventoryPolicy
              }
            }
          }
        }
      }
    }
  `);

  // Upsell Best Sellers
  const productsBestSellers = bestSellersData?.allShopifyCollection?.edges[0]?.node?.products || [];
  for (let i = 0; i < productsBestSellers.length; i += 1) {
    if (arrUpsellProducts.length > 5) {
      break;
    }
    if (
      !(productsBestSellers[i]?.tags || []).some((tag) =>
        discountsInfo.some(
          ({ matchType, matchTags }) => matchType === "exclude" && matchTags.includes(tag)
        )
      ) &&
      !productsBestSellers[i].variants.some((v) => v.inventoryPolicy === "CONTINUE") // Do not product in pre-order
    ) {
      arrUpsellProducts.push(productsBestSellers[i]?.handle);
    }
  }

  const [, recommendedProducts, recommendationId] = useRecommendation(
    "Side Cart",
    arrUpsellProducts,
    (product) =>
      !product ||
      (!handlesToExclude.has(product.shopify.handle) &&
        product.shopify.variants.length === 1 &&
        product.shopify.variants[0].availableForSale === true)
  );

  const recommendedData = recommendedProducts
    .filter((product) => product)
    .slice(0, 3)
    .map((p) => ({ ...p.shopify, title: p.contentful.title }));

  return {
    data: recommendedData,
    isLoading: recommendedProducts.some((product) => product === undefined),
    id: recommendationId,
  };
}
