import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useCurrentCountryCode } from "../../hooks/usePrices";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import { ComponentType } from "../../constants/ComponentType";
import COLORS from "../../constants/Colors";
import { Country } from "../../constants/Country";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import * as Styles from "./quantityPercentProgressTracker/styles.module.scss";

function QuantityPercentProgressTracker({
  eligibleItemCount,
  componentType,
  saleColors,
  isLuxe,
  isTextAbove = true,
  discountInfoMode = "current", // check useDiscountsInfo.js
}) {
  const discountInfo = useDiscountsInfo(discountInfoMode);
  const discounts = discountInfo
    .filter((discount) => discount.type === DISCOUNT_TYPES.QUANTITY_PERCENT)
    .map((discount) => discount.discounts)
    .flat();

  const countryCode = useCurrentCountryCode();
  const textColor = saleColors?.tierTextColorLuxe ?? COLORS.BLACK;
  const textColorReached = saleColors?.tierTextColorLuxeReached ?? COLORS.WHITE;
  const backgroundColorReached = saleColors?.tierBgColorLuxeReached ?? COLORS.POPPY;
  const borderColor = saleColors?.tierBorderColorLuxe ?? COLORS.BLACK;

  const totalTiers = discounts.length;
  let filledTiers = 0;
  let lastDiscount = 0;
  let nextThreshold = null;

  // Determine how many tiers are filled
  discounts.forEach(([threshold, discount], index) => {
    if (eligibleItemCount >= threshold) {
      filledTiers = index + 1;
      lastDiscount = discount;
    } else if (nextThreshold === null) {
      nextThreshold = threshold;
    }
  });

  const containerRef = useRef(null);
  const [totalWidth, setTotalWidth] = useState(0);

  // Measure container width when the component mounts or resizes
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setTotalWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth); // Cleanup listener
  }, []);

  const mergedTierWidth = totalTiers
    ? `${((totalWidth / totalTiers) * filledTiers).toFixed(2)}px`
    : "0px";

  let discountTitle;

  if (filledTiers === 0) {
    // No thresholds met
    const lastTwoTiers = discounts.slice(-2);
    const tierText = lastTwoTiers
      .map(
        ([threshold, discount], index, array) =>
          `BUY ${threshold}${index === array.length - 1 ? "+" : ""} GET ${discount}% OFF`
      )
      .join(", ");
    discountTitle = tierText;
  } else if (filledTiers < totalTiers) {
    // One or more thresholds met but not all
    const itemsNeeded = nextThreshold - eligibleItemCount;
    discountTitle = `YOU ARE ${itemsNeeded} ITEM${itemsNeeded > 1 ? "s" : ""} AWAY FROM ${
      discounts[filledTiers][1]
    }% OFF`;
  } else {
    // All thresholds met
    discountTitle = `ENJOY ${lastDiscount}% OFF + FREE SHIPPING`;
  }

  if (countryCode !== Country.US || !isLuxe) return null;

  const contentTiered = (
    <div
      className={`${Styles.discount_text_container} ${
        isTextAbove ? Styles.discount_text_above : Styles.discount_text_below
      }`}
    >
      <p className={`${Styles.discount_text}`}>{discountTitle}</p>
      <p className="microtext">* only with AL Luxe</p>
    </div>
  );
  return (
    <div
      className={`${Styles.discount_wrapper} ${
        componentType === ComponentType.PDP ? Styles.pdp : ""
      } ${componentType === ComponentType.ANNOUNCEMENT_BAR && Styles.announcement_bar}`}
    >
      {componentType === ComponentType.SIDE_CART && isTextAbove && contentTiered}
      <div className={Styles.progress_container} style={{ borderColor }} ref={containerRef}>
        {/* Filled tiers section */}
        {filledTiers > 0 && (
          <div
            className={Styles.tier}
            style={{
              width: mergedTierWidth,
              color: textColorReached,
              backgroundColor: backgroundColorReached,
              borderColor,
            }}
          >
            <div className={`${Styles.tier_text} ${Styles.tier_text_percentage_reached}`}>
              <span className={Styles.breakable_text}>YOU HAVE&nbsp;</span>
              <span className={Styles.breakable_text}>{lastDiscount}% OFF</span>
            </div>
          </div>
        )}

        {/* Remaining discount tiers */}
        {discounts.map(([threshold, discount], index) => {
          // Determine if the current tier is filled or not
          const isFilled = index < filledTiers; // Is this tier filled?
          // Skip rendering filled tiers
          if (isFilled) return null;

          const isLastTier = index === totalTiers - 1; // Last tier condition

          return (
            <div
              key={`${threshold}-${discount}`}
              className={Styles.tier}
              style={{ flex: 1, width: "auto", borderColor }}
            >
              <div className={Styles.tier_text} style={{ color: textColor }}>
                <div className={Styles.tier_text_item}>
                  {threshold}
                  {isLastTier && "+"}
                  {threshold === 1 ? " Item" : " Items"}{" "}
                </div>
                <div className={Styles.tier_text_item_percentage}>{discount}% OFF</div>
              </div>
            </div>
          );
        })}
      </div>
      {componentType === ComponentType.SIDE_CART && !isTextAbove && contentTiered}
    </div>
  );
}

export default QuantityPercentProgressTracker;

QuantityPercentProgressTracker.propTypes = {
  eligibleItemCount: PropTypes.number.isRequired,
  componentType: PropTypes.oneOf(Object.values(ComponentType)).isRequired,
  saleColors: PropTypes.shape({
    tierTextColorLuxe: PropTypes.string,
    tierTextColorLuxeReached: PropTypes.string,
    tierBgColorLuxe: PropTypes.string,
    tierBgColorLuxeReached: PropTypes.string,
    tierBorderColorLuxe: PropTypes.string,
  }),
  isLuxe: PropTypes.bool.isRequired,
  discountInfoMode: PropTypes.string,
};
