import { useCallback } from "react";
import useDiscountsInfo, { DISCOUNT_INFO_MODES } from "./useDiscountsInfo";
import { roundNumber } from "../context/helpers";
import DISCOUNT_TYPES from "../constants/DiscountTypes";

export default function useApplyLuxeDiscountToProductPrice() {
  const discountsInfo = useDiscountsInfo(DISCOUNT_INFO_MODES.LUXE_ONLY);

  return useCallback(
    (productPrice, productTags) => {
      const finalPrice = discountsInfo.reduce((price, discount) => {
        let isDiscountApplicable = false;
        if (discount.matchType === "include") {
          isDiscountApplicable = productTags.some((tag) => discount.matchTags.includes(tag));
        }
        if (discount.matchType === "exclude") {
          isDiscountApplicable = !productTags.some((tag) => discount.matchTags.includes(tag));
        }

        if (!isDiscountApplicable) {
          return price;
        }
        if (discount.type === DISCOUNT_TYPES.SITEWIDE_PERCENT) {
          return price * (1 - discount.value / 100);
        }
        if (discount.type === DISCOUNT_TYPES.QUANTITY_PERCENT) {
          return price * (1 - discount.thresholdResultPercentage[0] / 100);
        }
        console.warn(
          "useApplyLuxeDiscountToProductPrice: Unsupported discount type",
          discount.type
        );
        return price;
      }, parseFloat(productPrice));

      return roundNumber(finalPrice);
    },
    [discountsInfo]
  );
}
