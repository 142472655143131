import axios from "axios";
import { format } from "date-fns";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GeoContext } from "./GeoProvider";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";

const defaultShippingDelayContext = {
  etaLatestDeliveryDate: null,
  deliveryDateFormatted: null,
  deliveryTimelinesFormatted: null,
  isShippingEstimationLoading: true,
};
export const ShippingDelayContext = createContext(defaultShippingDelayContext);

function getLatestDeliveryDateFromPredictions(predictions) {
  return predictions.reduce((latestDatePrediction, p) => {
    const currentSelectedPredictionLatestDate =
      latestDatePrediction?.prediction?.earliest &&
      new Date(latestDatePrediction.prediction.earliest);
    const predictedLatestDate = new Date(p.prediction.earliest);
    return !currentSelectedPredictionLatestDate ||
      predictedLatestDate > currentSelectedPredictionLatestDate
      ? p
      : latestDatePrediction;
  }, null);
}

function formatDeliveryDate(date) {
  const day = format(date, "E");
  const month = format(date, "MMMM");
  const dateNumber = format(date, "do");
  return `${day}, ${month} ${dateNumber}`;
}

function ShippingDelayProvider({ children }) {
  const { sendReport } = useALError();
  const [etaLatestDeliveryDate, setETALatestDeliveryDate] = useState(
    defaultShippingDelayContext.etaLatestDeliveryDate
  );
  const [deliveryDateFormatted, setDeliveryDateFormatted] = useState(
    defaultShippingDelayContext.deliveryDateFormatted
  );
  const [deliveryTimelinesFormatted, setDeliveryTimelines] = useState(
    defaultShippingDelayContext.deliveryTimelinesFormatted
  );
  const [isShippingEstimationLoading, setIsShippingEstimationLoading] = useState(true);
  const { gePriceDetails, isLoadingCountry } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode3;

  useEffect(() => {
    if (isLoadingCountry) return;
    setIsShippingEstimationLoading(true);
    axios
      .get(
        `https://api.parcellab.com/v2/prediction?user=1617842&destination=${countryCode}&warehouse=ShipMonk%20-%20NY`
      )
      .then(({ data }) => {
        // Rule:
        // use highest latest value — for US exclude DHL express
        const prediction = getLatestDeliveryDateFromPredictions(
          countryCode === "USA"
            ? data.prediction.filter((p) => p.courier !== "dhl")
            : data.prediction
        );
        if (prediction) {
          const latest = new Date(prediction.prediction.earliest);
          setDeliveryTimelines(
            `${format(new Date(prediction.prediction.earliest), "MM/dd")} - ${format(
              new Date(prediction.prediction.latest),
              "MM/dd"
            )}`
          );
          const formattedDate = formatDeliveryDate(latest);
          setDeliveryDateFormatted(formattedDate); // Set the formatted date
          setETALatestDeliveryDate(latest);
        }
        setIsShippingEstimationLoading(false);
      })
      .catch((err) => {
        console.log(err);
        sendReport(err, { name: "ShippingDelayProvider", priority: "P5" });
        setIsShippingEstimationLoading(false);
      });
  }, [countryCode, isLoadingCountry]);

  const state = useMemo(
    () => ({
      etaLatestDeliveryDate,
      deliveryDateFormatted,
      deliveryTimelinesFormatted,
      isShippingEstimationLoading,
    }),
    [
      etaLatestDeliveryDate,
      deliveryDateFormatted,
      deliveryTimelinesFormatted,
      isShippingEstimationLoading,
    ]
  );

  return <ShippingDelayContext.Provider value={state}>{children}</ShippingDelayContext.Provider>;
}

export default ShippingDelayProvider;
