// extracted by mini-css-extract-plugin
export var awesome = "completeTheLook-module--awesome--13284";
export var bold = "completeTheLook-module--bold--cc1af";
export var bottom = "completeTheLook-module--bottom--eceae";
export var bottom_arrow = "completeTheLook-module--bottom_arrow--5f45a";
export var center = "completeTheLook-module--center--ce63d";
export var container = "completeTheLook-module--container--3ffe5";
export var container_title = "completeTheLook-module--container_title--922e0";
export var h1 = "completeTheLook-module--h1--405c2";
export var h1Bold = "completeTheLook-module--h1--bold--c7b31";
export var h1Uc = "completeTheLook-module--h1--uc--2a19d";
export var h2 = "completeTheLook-module--h2--4a3c1";
export var h2Bold = "completeTheLook-module--h2--bold--38ccb";
export var h2Uc = "completeTheLook-module--h2--uc--707c8";
export var h3 = "completeTheLook-module--h3--ced52";
export var h3Bold = "completeTheLook-module--h3--bold--2010d";
export var h3Uc = "completeTheLook-module--h3--uc--13beb";
export var h4 = "completeTheLook-module--h4--3c130";
export var h4Bold = "completeTheLook-module--h4--bold--18887";
export var h4Uc = "completeTheLook-module--h4--uc--ede2a";
export var linkUnderline = "completeTheLook-module--link--underline--cb86b";
export var microtext = "completeTheLook-module--microtext--d4646";
export var microtextBold = "completeTheLook-module--microtext--bold--14e84";
export var microtextUc = "completeTheLook-module--microtext--uc--0f65a";
export var nanotext = "completeTheLook-module--nanotext--32301";
export var product_container = "completeTheLook-module--product_container--bc3c6";
export var products_container = "completeTheLook-module--products_container--3fc72";
export var strikethrough = "completeTheLook-module--strikethrough--1d10e";
export var strikethroughBold = "completeTheLook-module--strikethrough--bold--9afdb";
export var strikethroughLg = "completeTheLook-module--strikethrough--lg--1e96c";
export var strikethroughSm = "completeTheLook-module--strikethrough--sm--4b40b";
export var subtext = "completeTheLook-module--subtext--a55d0";
export var subtextBold = "completeTheLook-module--subtext--bold--45b0c";
export var subtextUc = "completeTheLook-module--subtext--uc--51b35";
export var text = "completeTheLook-module--text--fa4fa";
export var textBold = "completeTheLook-module--text--bold--658e3";
export var textUc = "completeTheLook-module--text--uc--c5afd";
export var titleL = "completeTheLook-module--title-l--be5ff";
export var titleM = "completeTheLook-module--title-m--1517c";
export var titleS = "completeTheLook-module--title-s--fa614";
export var titleXl = "completeTheLook-module--title-xl--9f0c1";
export var titleXs = "completeTheLook-module--title-xs--0006d";
export var titleXxs = "completeTheLook-module--title-xxs--19f16";
export var uc = "completeTheLook-module--uc--562fa";
export var underline = "completeTheLook-module--underline--28f31";