import { ALLink } from "../components/ALComponents";

/**
 * Parse a string with one or both of the following formats:
 *      - [text]{link}
 *      - [text]{hexColor}
 *
 * Returns elements:
 *      - <ALLink href={link}>text</ALLink>
 *      - <span style={{ color: hexColor }}>text</span>
 */

/* eslint-disable import/prefer-default-export */

const LINK_COLOR_PATTERN = /\[([^\]]+)\]\{([^}]+)\}/g;
export function parseText(inputText) {
  const matches = Array.from(inputText.matchAll(LINK_COLOR_PATTERN));
  const parts = [];
  let lastIndex = 0;

  matches.forEach((match) => {
    if (match.index > lastIndex) {
      parts.push(inputText.substring(lastIndex, match.index));
    }

    if (match[2].startsWith("#")) {
      parts.push(<span style={{ color: match[2] }}>{match[1]}</span>);
    } else {
      parts.push(
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ALLink to={match[2]} key={match.index} rel="noopener noreferrer">
            {match[1]}
          </ALLink>
        </span>
      );
    }

    lastIndex = match.index + match[0].length;
  });

  if (lastIndex < inputText.length) {
    parts.push(inputText.substring(lastIndex));
  }
  return parts;
}
