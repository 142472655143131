import { memo } from "react";

import useAnnouncementBar from "../hooks/useAnnouncementBar";
import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";
import { useAnnouncementBarPopup } from "../context/AnnouncementBarProvider";

import Section from "./announcementBar/Section";
import AnnouncementBarPopup from "./announcementBar/AnnouncementBarPopup";

import * as Styles from "./announcementBar/styles.module.scss";

function AnnouncementBar() {
  const { popupOpen, setPopupOpen } = useAnnouncementBarPopup();

  const { backgroundColor, mobileContent, desktopContent, popup } = useAnnouncementBar();

  const backgroundColorAB = useResponsiveWithHydrationFix(
    backgroundColor.mobile,
    backgroundColor.desktop
  );

  const hasPopup = popup?.title && popup?.title !== "null";

  const contentElements = (
    <>
      <div className={Styles.announcementBar__mobileContent}>
        {mobileContent.map((content, i) => (
          <Section
            key={i}
            type="mobile"
            textArray={content.text}
            addShippingThreshold={content.addShippingThreshold}
            textNoThreshold={content.textNoThreshold}
            textPosition={content.textPosition?.toLowerCase()}
            enableRotatingText={content.enableRotatingText}
            timer={content.timer}
            displayLuxeProgressBar={content.displayLuxeProgressBar}
          />
        ))}
      </div>
      <div className={Styles.announcementBar__desktopContent}>
        {desktopContent.map((content, i) => (
          <Section
            key={i}
            type="desktop"
            textArray={content.text}
            addShippingThreshold={content.addShippingThreshold}
            textNoThreshold={content.textNoThreshold}
            textPosition={content.textPosition?.toLowerCase()}
            enableRotatingText={content.enableRotatingText}
            timer={content.timer}
            displayLuxeProgressBar={content.displayLuxeProgressBar}
          />
        ))}
      </div>
    </>
  );

  if (hasPopup) {
    return (
      <>
        <AnnouncementBarPopup
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
          backgroundColor={backgroundColorAB}
          popup={popup}
        />

        <button
          type="button"
          onClick={() => setPopupOpen(true)}
          style={{ backgroundColor: backgroundColorAB }}
          className={`${Styles.announcementBar} ${Styles.announcementBarButton} ab_container`}
        >
          {contentElements}
        </button>
      </>
    );
  }

  return (
    <div
      style={{ backgroundColor: backgroundColorAB }}
      className={`${Styles.announcementBar} ab_container`}
      data-testid="announcement-bar"
    >
      {contentElements}
    </div>
  );
}

export default memo(AnnouncementBar);
