import { useCallback, useContext, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import axios from "axios";

import { GeoContext } from "../context/GeoProvider";
import { getBasePrices_NEW, roundNumber, edgesToArray } from "../context/helpers";
import { useExclusionTags } from "./useExclusionTags";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import useDiscountsInfo from "./useDiscountsInfo";
import { getShopifyStoreByCountry } from "../../locale-shopifies";
import { locales } from "../../locales";

const ONE_HOUR = 60 * 60 * 1000;

export const formatPriceAmount = (amount, currencyCode, countryCode) => {
  if (!currencyCode || !countryCode) return "";

  /*
   * This is a fix for the price display for CA
   * We want it to be displayed like this: CA$100 and setting the country to US is the only way at the moment
   * https://stackoverflow.com/questions/56611301/numberformat-for-cad-currency-not-right
   */
  const country = countryCode === "CA" ? "US" : countryCode;

  const formatterOptions = {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    trailingZeroDisplay: "stripIfInteger",
  };

  // Formatter currencyDisplay `narrowSymbol` might not be compatible with older safari versions,
  // so default to `symbol` if it fails
  let formatter;
  try {
    formatter = new Intl.NumberFormat(country, {
      ...formatterOptions,
      currencyDisplay: countryCode === "US" ? "narrowSymbol" : "symbol",
    });
  } catch (e) {
    formatter = new Intl.NumberFormat(country, {
      ...formatterOptions,
      currencyDisplay: "symbol",
    });
  }

  // In case "symbol" displays the dollar price like "US$65", remove the "US" part
  // Server side Node < 19 does not support trailingZeroDisplay so remove it in case found
  if (countryCode === "US") {
    return formatter.format(amount).replace("US", "").replace(".00", "");
  }
  return formatter.format(amount);
};

async function queryShopify({ query, storeName, accessToken }) {
  const response = await axios.post(
    `https://${storeName}.myshopify.com/api/2022-10/graphql.json`,
    query,
    {
      headers: {
        "Content-Type": "application/graphql",
        "X-Shopify-Storefront-Access-Token": accessToken,
      },
    }
  );
  return response.data.data;
}

const PRODUCT_FIELDS = `
id
handle
variants(first: 20) {
    edges {
        node {
          sku
          id
          priceV2 {
              amount
              currencyCode
          }
          compareAtPriceV2 {
              amount
              currencyCode
          }
        }
    }
}
`;

function unpackPriceInfo(product) {
  const variants = edgesToArray(product.variants);
  const variant = variants[0];

  return {
    id: product.id,
    handle: product.handle,
    price: variant.priceV2.amount,
    compareAtPrice: variant.compareAtPriceV2?.amount,
    currencyCode: variant.priceV2.currencyCode,
    variants: product.variants,
  };
}

async function fetchProductPriceInfo({ productHandle, countryCode }) {
  const { name: storeName, storefrontAccessToken } = useShopify(countryCode);

  if (!countryCode || countryCode === "N/A") return null;

  const data = await queryShopify({
    storeName,
    accessToken: storefrontAccessToken,
    query: `
query @inContext(country: ${countryCode}) {
  productByHandle(handle: "${productHandle}") {
    ${PRODUCT_FIELDS}
  }
}`,
  });

  const product = data.productByHandle;

  if (!product) {
    return null;
  }

  return unpackPriceInfo(data.productByHandle);
}

export function useCurrentCountryCode() {
  const { gePriceDetails } = useContext(GeoContext);
  return gePriceDetails?.CountryCode;
}

function getLocaleByCountryCode(countryCode) {
  return (
    locales.find((l) => l.country === countryCode) ?? {
      locale: "en-US",
      countryName: "United States",
      country: "US",
      currency: "USD",
      currencySymbol: "$",
      iso3: "USA",
      timeZone: "America/New_York",
    }
  );
}

export function useCurrent3digitCountryCode() {
  const countryCode2digit = useCurrentCountryCode();
  const locale = getLocaleByCountryCode(countryCode2digit);
  const countryCode3digit = locale?.iso3 ?? "USA";
  return countryCode3digit;
}

export function useCurrentCurrencySymbol() {
  const countryCode = useCurrentCountryCode();
  const locale = getLocaleByCountryCode(countryCode);
  const currencySymbol = locale.currencySymbol ?? "$";
  return currencySymbol;
}

export function useCurrentCurrencyCode() {
  const countryCode = useCurrentCountryCode();
  const locale = getLocaleByCountryCode(countryCode);
  const currencyCode = locale.currency ?? "USD";
  return currencyCode;
}

/**
 *
 * @returns a function that inserts the currency symbol according to the current
 * locale setting: (amount:any) => string
 */
export function useCurrentFormatCurrency() {
  const { gePriceDetails } = useContext(GeoContext);
  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;

  return useCallback(
    (amount) => formatPriceAmount(amount, currencyCode, countryCode),
    [countryCode, currencyCode]
  );
}

function getBasePrices(compareAtPrice, price, discountsInfo, isBannedProduct) {
  const p = getBasePrices_NEW(compareAtPrice, price, discountsInfo);
  if (isBannedProduct) {
    p.full_price = compareAtPrice;
    p.final_price = price;
  }
  p.full_price = p.full_price ?? price;
  delete p.currency;
  // recalculate the discount_given since what's in there doesn't account for banned products
  p.discount_given = `${roundNumber(((p.full_price - p.final_price) / p.full_price) * 100)}`;
  return p;
}

function formatPrices(
  pricesFromShopify,
  discountsInfo,
  currencySymbol,
  isBannedProduct,
  countryCode,
  currencyCode
) {
  const p = getBasePrices(
    pricesFromShopify.compareAtPrice,
    pricesFromShopify.price,
    discountsInfo,
    isBannedProduct
  );

  const fullPrice = roundNumber(p.full_price);
  const finalPrice = roundNumber(p.final_price);

  const variants = edgesToArray(pricesFromShopify.variants).map((v) => ({
    id: v.id,
    sku: v.sku,
    price: roundNumber(v.priceV2.amount),
    compareAtPrice: v.compareAtPriceV2?.amount,
    currencySymbol,
    currency: currencySymbol,
    ...getBasePrices(v.compareAtPriceV2?.amount, v.priceV2.amount, discountsInfo, isBannedProduct),
  }));

  const discountAmount = fullPrice - finalPrice;

  return {
    ...pricesFromShopify,
    fullPrice,
    finalPrice,
    currencySymbol,
    // The following are duplicate fields for legacy reasons
    full_price: fullPrice,
    final_price: finalPrice,
    currency: currencySymbol,
    formattedFullPrice: formatPriceAmount(fullPrice, currencyCode, countryCode),
    formattedFinalPrice: formatPriceAmount(finalPrice, currencyCode, countryCode),
    formattedDiscount: formatPriceAmount(roundNumber(discountAmount), currencyCode, countryCode),
    discountAmount,
    discount_given: p.discount_given,
    compareAtPrice: pricesFromShopify.compareAtPrice,
    price: pricesFromShopify.price,
    variants,
  };
}

function useShopify(countryCode) {
  const shopify = getShopifyStoreByCountry(countryCode);
  return shopify;
}

// Returns a list of prices, each corresponding to the handles passed as input
export function usePriceInfo({
  handles,
  tags = {},
  shouldUseDiscount = false,
  shouldFetchPrice = true,
}) {
  const { sendReport } = useALError();
  const { isLoadingCountry, gePriceDetails } = useContext(GeoContext);
  const discountsInfo = useDiscountsInfo();

  const exclusionTags = useExclusionTags();

  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;
  const currencySymbol = gePriceDetails?.CurrencySymbol;

  const discountInfoKey = discountsInfo?.[0]?.code ?? "null";

  // useIsFetching makes filters very slow!
  const isFetchingCollection = false; // useIsFetching(['prices-collection']) > 0;

  const discountToUse = shouldUseDiscount ? discountsInfo : null;

  const results = useQueries({
    queries: handles.map((productHandle) => {
      const isBannedProduct = (tags[productHandle] || []).some((r) => exclusionTags.includes(r));
      return {
        queryKey: ["prices", { handle: productHandle, countryCode, discountInfoKey }],
        queryFn: async () => {
          const priceInfo = await fetchProductPriceInfo({
            productHandle,
            countryCode,
          });
          if (!priceInfo) {
            return null;
          }
          return formatPrices(
            priceInfo,
            discountToUse,
            currencySymbol,
            isBannedProduct,
            countryCode,
            currencyCode
          );
        },
        staleTime: ONE_HOUR,
        enabled:
          !isLoadingCountry &&
          !isFetchingCollection &&
          !!productHandle &&
          !!countryCode &&
          !!discountsInfo &&
          discountsInfo.length > 0 &&
          discountsInfo?.[0] !== "empty" &&
          shouldFetchPrice,
        useErrorBoundary: true,
        meta: { name: "usePriceInfo", priority: "P1", sendReport },
      };
    }),
  });
  return results;
}

export function usePriceLocalInfo({
  handle,
  price,
  selectedVariant = null,
  tags = {},
  shouldUseDiscount = false,
  shouldComputePrice = true,
}) {
  const { sendReport } = useALError();
  const { gePriceDetails } = useContext(GeoContext);

  const discountsInfo = useDiscountsInfo();

  const exclusionTags = useExclusionTags();

  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;
  const currencySymbol = gePriceDetails?.CurrencySymbol;

  const formattedPrice = useMemo(() => {
    try {
      if (!price || !shouldComputePrice) {
        return null;
      }
      const discountToUse = shouldUseDiscount ? discountsInfo : null;

      const isBannedProduct = (tags[handle] || []).some((r) => exclusionTags.includes(r));

      // Get price from variant
      let priceVariants = null;
      if (selectedVariant) {
        priceVariants = price?.variants?.find((v) => v?.id === selectedVariant?.id);
      } else {
        priceVariants = price?.variants[0];
      }
      const priceInfoLocal = {
        compareAtPrice: priceVariants?.compareAtPriceV2?.amount || null,
        price: priceVariants?.priceV2?.amount,
        currencyCode: priceVariants?.priceV2?.currencyCode,
        handle,
        id: price?.shopifyId?.replace(`__${countryCode}`, ""),
        variants: { edges: price?.variants?.map((v) => ({ node: v })) },
      };

      return formatPrices(
        priceInfoLocal,
        discountToUse,
        currencySymbol,
        isBannedProduct,
        countryCode,
        currencyCode
      );
    } catch (error) {
      sendReport(error, { name: "usePriceLocalInfo", priority: "P1" });
      throw error;
    }
  }, [
    countryCode,
    currencyCode,
    currencySymbol,
    discountsInfo,
    exclusionTags,
    handle,
    price,
    selectedVariant,
    sendReport,
    shouldUseDiscount,
    tags,
    shouldComputePrice,
  ]);
  return formattedPrice;
}

export function usePricesLocalInfo({
  handles,
  allPrices = [],
  selectedVariant = null,
  tags = {},
  shouldUseDiscount = false,
}) {
  const { sendReport } = useALError();
  const { gePriceDetails } = useContext(GeoContext);

  const discountsInfo = useDiscountsInfo();
  const exclusionTags = useExclusionTags();

  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;
  const currencySymbol = gePriceDetails?.CurrencySymbol;

  const formattedPrices = useMemo(() => {
    try {
      return handles.map((handle) => {
        const discountToUse = shouldUseDiscount ? discountsInfo : null;

        const isBannedProduct = (tags[handle] || []).some((r) => exclusionTags.includes(r));

        // Get price for country
        const priceProduct = allPrices
          .filter((p) => p?.node?.handle === handle)
          .find((p) => p?.node?.country === countryCode)?.node;
        if (!priceProduct) return {};

        // Get price from variant
        let priceVariants = null;
        if (selectedVariant) {
          priceVariants = priceProduct?.variants.find((v) => v?.id === selectedVariant?.id);
        } else {
          priceVariants = priceProduct?.variants[0];
        }
        const priceInfoLocal = {
          compareAtPrice: priceVariants?.compareAtPriceV2?.amount || null,
          price: priceVariants?.priceV2?.amount,
          currencyCode: priceVariants?.priceV2?.currencyCode,
          handle,
          id: priceProduct?.shopifyId?.replace(`__${countryCode}`, ""),
          variants: { edges: priceProduct?.variants.map((v) => ({ node: v })) },
        };

        return formatPrices(
          priceInfoLocal,
          discountToUse,
          currencySymbol,
          isBannedProduct,
          countryCode,
          currencyCode
        );
      });
    } catch (error) {
      sendReport(error, { name: "usePriceLocalInfo", priority: "P1" });
      throw error;
    }
  }, [
    countryCode,
    currencyCode,
    currencySymbol,
    discountsInfo,
    exclusionTags,
    handles,
    allPrices,
    selectedVariant,
    sendReport,
    shouldUseDiscount,
    tags,
  ]);
  return formattedPrices;
}

export function usePricesByCollectionLocal({
  productHandlesInCollection,
  prices,
  productsTags,
  shouldUseDiscount = false,
}) {
  const collectionPrices = usePricesLocalInfo({
    handles: productHandlesInCollection,
    allPrices: prices,
    tags: productsTags,
    shouldUseDiscount,
    debug_type: "usePricesByCollectionLocal",
  });

  return collectionPrices;
}

export function fillProductPrices(product, priceInfo = {}) {
  const newProduct = {
    ...product,
    ...priceInfo,
    variants: (product?.variants || []).map((v) => ({
      ...(priceInfo?.variants?.find((p) => p.id === v.id) || {}),
      ...v,
    })),
  };
  return newProduct;
}

export function useFillProductsPrices({ products, allLocalPrices = [], shouldUseDiscount = true }) {
  const { productHandles, productsTags } = useMemo(() => {
    const filteredProducts = products.filter((p) => !!p);
    return {
      productHandles: filteredProducts.map((p) => p.handle),
      productsTags: filteredProducts.reduce((acc, p) => ({ ...acc, [p.handle]: p.tags }), {}),
    };
  }, [products]);

  const pricesLocalInfo = usePricesLocalInfo({
    handles: productHandles,
    tags: productsTags,
    shouldUseDiscount,
    allPrices: allLocalPrices,
  });
  const queriesResult = usePriceInfo({
    handles: productHandles,
    tags: productsTags,
    shouldUseDiscount,
    shouldFetchPrice: products.some(
      (_p, i) => !pricesLocalInfo?.[i] || Object.keys(pricesLocalInfo[i]).length === 0
    ),
  });

  const memoizedProducts = useMemo(
    () =>
      products
        .filter((p) => !!p)
        .map((p, idx) => {
          const hasLocalPrice =
            pricesLocalInfo?.[idx] && Object.keys(pricesLocalInfo[idx]).length > 0;
          const price = (hasLocalPrice && pricesLocalInfo[idx]) || queriesResult[idx]?.data;
          if (price) {
            return fillProductPrices(p, price);
          }
          return p;
        }),
    [products, queriesResult, pricesLocalInfo]
  );
  return memoizedProducts;
}

export function useFillProductPrices({ product, localPrice = null, shouldUseDiscount = false }) {
  const memoizedProductList = useMemo(() => [product], [product]);
  const memoizedLocalPriceList = useMemo(
    () => (localPrice ? [{ node: localPrice }] : []),
    [localPrice]
  );
  return useFillProductsPrices({
    products: memoizedProductList,
    shouldUseDiscount,
    allLocalPrices: memoizedLocalPriceList,
  })[0];
}

export function useManyProductPrices({ products, allLocalPrices = [], shouldUseDiscount = false }) {
  const { productHandles, productsTags } = useMemo(() => {
    const filteredProducts = products.filter((p) => !!p);
    return {
      productHandles: filteredProducts.map((p) => p.handle),
      productsTags: filteredProducts.reduce((acc, p) => ({ ...acc, [p.handle]: p.tags }), {}),
    };
  }, [products]);

  const pricesLocalInfo = usePricesLocalInfo({
    handles: productHandles,
    tags: productsTags,
    shouldUseDiscount,
    allPrices: allLocalPrices,
  });
  const queriesResult = usePriceInfo({
    handles: productHandles,
    tags: productsTags,
    shouldUseDiscount,
    shouldFetchPrice: products.some(
      (_p, i) => !pricesLocalInfo?.[i] || Object.keys(pricesLocalInfo[i]).length === 0
    ),
  });
  return useMemo(
    () =>
      productHandles.map((_, idx) => {
        const hasLocalPrice =
          pricesLocalInfo?.[idx] && Object.keys(pricesLocalInfo[idx]).length > 0;
        const price = (hasLocalPrice && pricesLocalInfo[idx]) || queriesResult[idx]?.data;
        if (price) {
          return price;
        }
        return queriesResult[idx]?.isInitialLoading ? undefined : queriesResult[idx]?.data;
      }),
    [productHandles, pricesLocalInfo, queriesResult]
  );
}

export function useProductPrices({ product, localPrice = null, shouldUseDiscount = false }) {
  const memoizedProductList = useMemo(() => [product], [product]);
  const memoizedLocalPriceList = useMemo(
    () => (localPrice ? [{ node: localPrice }] : []),
    [localPrice]
  );
  return useManyProductPrices({
    products: memoizedProductList,
    shouldUseDiscount,
    allLocalPrices: memoizedLocalPriceList,
  })[0];
}
