// extracted by mini-css-extract-plugin
export var alternative_variant = "styles-module--alternative_variant--83bc7";
export var awesome = "styles-module--awesome--54fbe";
export var blockContainer = "styles-module--block--container--46ae2";
export var blockContainerActive = "styles-module--block--container--active--e225f";
export var blockContainerFullWidth = "styles-module--block--container--fullWidth--1ea6e";
export var blockContainerUnavailable = "styles-module--block--container--unavailable--554ae";
export var bold = "styles-module--bold--dfb01";
export var bubble = "styles-module--bubble--64886";
export var bubbleContainer = "styles-module--bubble--container--c13c1";
export var bubbleContainerActive = "styles-module--bubble--container--active--facc0";
export var bubbleContainerActiveBorder = "styles-module--bubble--container--active--border--f590c";
export var bubbleContainerUnavailable = "styles-module--bubble--container--unavailable--f42c2";
export var center = "styles-module--center--325c4";
export var colorName = "styles-module--colorName--adb42";
export var container = "styles-module--container--bd7fa";
export var containerMultiStep = "styles-module--containerMultiStep--ee6d8";
export var container_cp = "styles-module--container_cp--9afbe";
export var h1 = "styles-module--h1--93bed";
export var h1Bold = "styles-module--h1--bold--b293c";
export var h1Uc = "styles-module--h1--uc--a0719";
export var h2 = "styles-module--h2--922f1";
export var h2Bold = "styles-module--h2--bold--f603c";
export var h2Uc = "styles-module--h2--uc--1af09";
export var h3 = "styles-module--h3--6f8e6";
export var h3Bold = "styles-module--h3--bold--01f48";
export var h3Uc = "styles-module--h3--uc--9ccf1";
export var h4 = "styles-module--h4--f1e4a";
export var h4Bold = "styles-module--h4--bold--10d73";
export var h4Uc = "styles-module--h4--uc--c2058";
export var linkUnderline = "styles-module--link--underline--555be";
export var microtext = "styles-module--microtext--27473";
export var microtextBold = "styles-module--microtext--bold--e4eb2";
export var microtextUc = "styles-module--microtext--uc--cd95e";
export var nanotext = "styles-module--nanotext--92a7a";
export var strikethrough = "styles-module--strikethrough--74359";
export var strikethroughBold = "styles-module--strikethrough--bold--1eafe";
export var strikethroughLg = "styles-module--strikethrough--lg--e7aab";
export var strikethroughSm = "styles-module--strikethrough--sm--78b37";
export var subtext = "styles-module--subtext--feb22";
export var subtextBold = "styles-module--subtext--bold--04843";
export var subtextUc = "styles-module--subtext--uc--1540b";
export var text = "styles-module--text--85920";
export var textBold = "styles-module--text--bold--b83d0";
export var textUc = "styles-module--text--uc--78b92";
export var titleL = "styles-module--title-l--79aef";
export var titleM = "styles-module--title-m--4b5a9";
export var titleS = "styles-module--title-s--d1777";
export var titleXl = "styles-module--title-xl--b7b7c";
export var titleXs = "styles-module--title-xs--67fd4";
export var titleXxs = "styles-module--title-xxs--69e0c";
export var uc = "styles-module--uc--1458c";
export var underline = "styles-module--underline--92355";
export var white_bubble = "styles-module--white_bubble--a9dec";
export var wrapper = "styles-module--wrapper--a9289";
export var wrapper_cp = "styles-module--wrapper_cp--55e66";
export var wrapper_cp_lazy = "styles-module--wrapper_cp_lazy--20db4";
export var wrapper_pdp = "styles-module--wrapper_pdp--f2ae7";