import { useContext, useEffect, useState } from "react";
import { getShopifyStoreByCountry } from "../../locale-shopifies";
import { HiddenElementsContext } from "../context/HiddenElements";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { isBrowser } from "../context/helpers";

function FloatingChat() {
  const { shouldHide } = useContext(HiddenElementsContext);
  const countryCode = useCurrentCountryCode();
  const [isKustomerStarted, setKustomerStarted] = useState(false);
  // const { kustomerAssistantId, kustomerBrandId } = getShopifyStoreByCountry(countryCode);
  const { kustomerBrandId } = getShopifyStoreByCountry(countryCode);

  if (!isBrowser) {
    return null;
  }

  function waitForKustomer(callback) {
    const hasKustomer = typeof window.Kustomer !== "undefined";
    if (hasKustomer && !isKustomerStarted) {
      callback();
    } else {
      setTimeout(() => {
        waitForKustomer(callback);
      }, 100);
    }
  }

  useEffect(() => {
    waitForKustomer(() => {
      try {
        window.Kustomer.start(
          {
            brandId: kustomerBrandId,
            chatIconSize: { width: 60, height: 60 },
            chatIconPosition: { alignment: "right" },
            // assistantId: kustomerAssistantId, // Luxe automation
            assistantId: "67ae2f5eb03fd27ca9e3e110", // TMP Assistant
          },
          () => {
            try {
              try {
                window.Kustomer.addListener("onConversationCreate", (res) => {
                  window.Kustomer.describeConversation({
                    conversationId: res.conversationId,
                    customAttributes: {
                      locationUrl: window.location.href,
                    },
                  });
                });
              } catch (error) {
                console.log("Error kustomer custom attributes");
                console.log(error);
              }
              setKustomerStarted(true);
            } catch (error) {
              console.log("Error kustomer");
              console.log(error);
            }
          }
        );
      } catch (error) {
        console.log("error Kustomer");
        console.error(error);
      }
    });
  }, []);

  useEffect(() => {
    const kustomerDesktop = document.getElementById("kustomer-ui-sdk-iframe");
    if (kustomerDesktop) {
      kustomerDesktop.style.display = shouldHide ? "none" : "block";
    }
  }, [shouldHide, isKustomerStarted]);

  return null;
}

export default FloatingChat;
