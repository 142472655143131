// extracted by mini-css-extract-plugin
export var close_button = "styles-module--close_button--32fd0";
export var popup_ab = "styles-module--popup_ab--aa9bd";
export var popup_container = "styles-module--popup_container--e9e3a";
export var popup_container_custom_image = "styles-module--popup_container_custom_image--34425";
export var popup_container_image = "styles-module--popup_container_image--cc2c3";
export var popup_container_subtitle = "styles-module--popup_container_subtitle--43ed8";
export var popup_shipping_info_desktop = "styles-module--popup_shipping_info_desktop--6d879";
export var popup_shipping_info_mobile = "styles-module--popup_shipping_info_mobile--96576";
export var popup_shipping_info_mobile_content = "styles-module--popup_shipping_info_mobile_content--19300";
export var popup_shipping_info_mobile_title = "styles-module--popup_shipping_info_mobile_title--d3524";
export var popup_title_deadline = "styles-module--popup_title_deadline--4dda1";
export var popup_title_deadline_c = "styles-module--popup_title_deadline_c--69ced";
export var popup_title_deadline_content = "styles-module--popup_title_deadline_content--c0c7a";
export var popup_title_shipping_date = "styles-module--popup_title_shipping_date--733ee";
export var popup_title_shipping_type = "styles-module--popup_title_shipping_type--1f27e";