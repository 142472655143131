// extracted by mini-css-extract-plugin
export var announcement_bar = "styles-module--announcement_bar--bd9aa";
export var awesome = "styles-module--awesome--e3770";
export var bold = "styles-module--bold--2990b";
export var breakable_text = "styles-module--breakable_text--4ba53";
export var center = "styles-module--center--258b8";
export var discount_text = "styles-module--discount_text--9a8b6";
export var discount_text_above = "styles-module--discount_text_above--db06b";
export var discount_text_below = "styles-module--discount_text_below--69d72";
export var discount_text_container = "styles-module--discount_text_container--81d30";
export var discount_wrapper = "styles-module--discount_wrapper--d8740";
export var gifting_text_padding = "styles-module--gifting_text_padding--60a4e";
export var h1 = "styles-module--h1--e510f";
export var h1Bold = "styles-module--h1--bold--fedc5";
export var h1Uc = "styles-module--h1--uc--66b9d";
export var h2 = "styles-module--h2--d0eee";
export var h2Bold = "styles-module--h2--bold--aaf49";
export var h2Uc = "styles-module--h2--uc--2cde3";
export var h3 = "styles-module--h3--a23f4";
export var h3Bold = "styles-module--h3--bold--adafe";
export var h3Uc = "styles-module--h3--uc--c45e0";
export var h4 = "styles-module--h4--fae23";
export var h4Bold = "styles-module--h4--bold--0cf3b";
export var h4Uc = "styles-module--h4--uc--86b7e";
export var linkUnderline = "styles-module--link--underline--1a8d8";
export var microtext = "styles-module--microtext--9b73e";
export var microtextBold = "styles-module--microtext--bold--d1721";
export var microtextUc = "styles-module--microtext--uc--ff995";
export var nanotext = "styles-module--nanotext--2c9de";
export var pdp = "styles-module--pdp--ba78f";
export var progress_container = "styles-module--progress_container--9ccaa";
export var strikethrough = "styles-module--strikethrough--4c82b";
export var strikethroughBold = "styles-module--strikethrough--bold--64dd7";
export var strikethroughLg = "styles-module--strikethrough--lg--e278f";
export var strikethroughSm = "styles-module--strikethrough--sm--9eee3";
export var subtext = "styles-module--subtext--365eb";
export var subtextBold = "styles-module--subtext--bold--3c892";
export var subtextUc = "styles-module--subtext--uc--89ed1";
export var text = "styles-module--text--e188e";
export var textBold = "styles-module--text--bold--bffec";
export var textUc = "styles-module--text--uc--9006e";
export var tier = "styles-module--tier--5eb42";
export var tier_text = "styles-module--tier_text--53e5d";
export var tier_text_item = "styles-module--tier_text_item--9f938";
export var tier_text_item_percentage = "styles-module--tier_text_item_percentage--10f3d";
export var tier_text_percentage_reached = "styles-module--tier_text_percentage_reached--48942";
export var titleL = "styles-module--title-l--751aa";
export var titleM = "styles-module--title-m--5c6ab";
export var titleS = "styles-module--title-s--b78c6";
export var titleXl = "styles-module--title-xl--82749";
export var titleXs = "styles-module--title-xs--75c2b";
export var titleXxs = "styles-module--title-xxs--d055d";
export var uc = "styles-module--uc--918da";
export var underline = "styles-module--underline--b3004";