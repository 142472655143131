// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--1264e";
export var bold = "styles-module--bold--c660f";
export var center = "styles-module--center--3a793";
export var h1 = "styles-module--h1--3393d";
export var h1Bold = "styles-module--h1--bold--4ffec";
export var h1Uc = "styles-module--h1--uc--3c891";
export var h2 = "styles-module--h2--93afe";
export var h2Bold = "styles-module--h2--bold--d02b2";
export var h2Uc = "styles-module--h2--uc--7a3f4";
export var h3 = "styles-module--h3--a38b3";
export var h3Bold = "styles-module--h3--bold--31d72";
export var h3Uc = "styles-module--h3--uc--7ba90";
export var h4 = "styles-module--h4--f091c";
export var h4Bold = "styles-module--h4--bold--ee537";
export var h4Uc = "styles-module--h4--uc--85163";
export var linkUnderline = "styles-module--link--underline--669ae";
export var microtext = "styles-module--microtext--bd263";
export var microtextBold = "styles-module--microtext--bold--67b87";
export var microtextUc = "styles-module--microtext--uc--11df8";
export var nanotext = "styles-module--nanotext--18d47";
export var strikethrough = "styles-module--strikethrough--69326";
export var strikethroughBold = "styles-module--strikethrough--bold--0cf43";
export var strikethroughLg = "styles-module--strikethrough--lg--30a4d";
export var strikethroughSm = "styles-module--strikethrough--sm--e9996";
export var subtext = "styles-module--subtext--09b11";
export var subtextBold = "styles-module--subtext--bold--a856d";
export var subtextUc = "styles-module--subtext--uc--01446";
export var text = "styles-module--text--e397d";
export var textBold = "styles-module--text--bold--a6237";
export var textUc = "styles-module--text--uc--e3806";
export var timer = "styles-module--timer--4fe0c";
export var titleL = "styles-module--title-l--2a3cf";
export var titleM = "styles-module--title-m--5c152";
export var titleS = "styles-module--title-s--e04b7";
export var titleXl = "styles-module--title-xl--1bae2";
export var titleXs = "styles-module--title-xs--87d87";
export var titleXxs = "styles-module--title-xxs--38f97";
export var uc = "styles-module--uc--57b44";
export var underline = "styles-module--underline--e6250";