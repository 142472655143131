import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { queryProductData } from "../../../context/products";
import { useCurrentCountryCode, usePriceInfo } from "../../../hooks/usePrices";

import PriceContainer from "../../PriceContainer";
import { ComponentType } from "../../../constants/ComponentType";
import CheckSquareIcon from "../../icons/svgs/check-square.svg";

import * as Styles from "./quickUpsell/styles.module.scss";

function QuickUpsell({ tags, optionalProducts, setOptionalProducts }) {
  const countryCode = useCurrentCountryCode();

  const [product, setProduct] = useState();

  const isGold = tags.includes("Color:Gold");

  const handleToBeAdded = isGold
    ? "necklace-extender-chain-extender"
    : "necklace-extender-chain-extender-silver";

  useEffect(() => {
    const getProduct = async () => {
      const productToBeAdded = await queryProductData([handleToBeAdded], "short", countryCode);
      setProduct(productToBeAdded[0] || {});
    };
    getProduct();
  }, [countryCode, handleToBeAdded]);

  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [product?.shopify?.handle],
    tags: { [product?.shopify?.handle]: product?.shopify?.tags },
  });

  const handleAddProduct = () => {
    if (optionalProducts.length > 0) {
      setOptionalProducts([]);
    } else {
      setOptionalProducts([{ ...product.shopify, prices }]);
    }
  };

  if (!product) {
    return null;
  }

  return (
    <div className={Styles.container}>
      {optionalProducts.length > 0 ? (
        <div className={Styles.content__text}>
          <CheckSquareIcon
            width="16"
            height="16"
            onClick={handleAddProduct}
            data-testid="checkbox-checked"
          />
          <p className="subtext">
            Necklace extender <span className="bold">added</span>
          </p>
        </div>
      ) : (
        <div className={Styles.content}>
          <div className={Styles.content__text}>
            <input type="checkbox" onChange={handleAddProduct} data-testid="checkbox" />
            <p className="subtext">
              <span className="bold">Add</span> a necklace extender&nbsp;
              <span className="bold">(length 2&quot;)</span>
            </p>
          </div>

          <PriceContainer
            productId={product?.shopify?.id}
            prices={prices}
            pricesLoading={pricesLoading}
            componentType={ComponentType.PDP_QUICK_UPSELL}
          />
        </div>
      )}
      <div className={Styles.line} />
    </div>
  );
}

QuickUpsell.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  setOptionalProducts: PropTypes.func,
  optionalProducts: PropTypes.array,
};

export default QuickUpsell;
