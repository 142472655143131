import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import GiftIcon from "../icons/svgs/gift.svg";
import * as Styles from "./giftWithPurchaseInfo/style.module.scss";

function GiftWithPurchaseInfo({ productHandle, giftDiscount }) {
  const formatCurrency = useCurrentFormatCurrency();

  // Early return if giftDiscount or required nested properties are missing
  if (!giftDiscount?.freegift?.product?.shopify) {
    return null; // Or a fallback UI component
  }

  const { shopify } = giftDiscount.freegift.product;
  if (productHandle === shopify.handle) return null;

  const productTitle = shopify.title?.split(" - ")[0] || shopify.title || "Gift item";
  const productImage = shopify.images?.[0];
  const productPrice = shopify.variants?.[0]?.price;
  const productType = shopify.productType || "";
  const minQuantity = giftDiscount.freegift.minEligibleQuantity;

  return (
    <div className={Styles.giftWithPurchaseInfoContainer}>
      {productImage && (
        <div className={Styles.giftWithPurchaseInfoImageContainer}>
          <img
            src={productImage.url}
            alt={productImage.altText || `${productTitle} image`}
            style={{
              transform:
                productType === "Necklaces" ? "scale(1.7) translateY(-32px)" : "translateY(-10px)",
            }}
            loading="lazy"
          />
        </div>
      )}
      <div className={Styles.giftWithPurchaseInfoContainerContent}>
        <p className={`subtext bold ${Styles.giftWithPurchaseInfoContainerContentTitle}`}>
          <span>LIMITED STOCK!&nbsp;&nbsp;</span>
          <GiftIcon width="15" height="15" fill="none" stroke="#e94b2f" aria-hidden="true" />
        </p>
        <p className="subtext bold">
          Free {productTitle.toLowerCase()}{" "}
          {productPrice && `(${formatCurrency(productPrice)} value)`} with purchase of {minQuantity}
          {"+ "}
          {minQuantity === 1 ? "item" : "items"}.
        </p>
      </div>
    </div>
  );
}

GiftWithPurchaseInfo.propTypes = {
  giftDiscount: PropTypes.shape({
    freegift: PropTypes.shape({
      product: PropTypes.shape({
        shopify: PropTypes.shape({
          title: PropTypes.string,
          productType: PropTypes.string,
          images: PropTypes.arrayOf(
            PropTypes.shape({
              url: PropTypes.string,
              altText: PropTypes.string,
            })
          ),
          variants: PropTypes.arrayOf(
            PropTypes.shape({
              price: PropTypes.number,
            })
          ),
        }),
      }),
      minEligibleQuantity: PropTypes.number,
    }),
  }).isRequired,
};

export default withALErrorBoundary({
  name: "GiftWithPurchaseInfo",
  priority: "P1",
})(GiftWithPurchaseInfo);
