import { useContext, useEffect, useState } from "react";
import StoreContext from "../context/store";
import { isBrowser } from "../context/helpers";

function useSticky(isHeader = false, topOffset = "0px") {
  const { displayAB } = useContext(StoreContext);
  const [offset, setOffset] = useState({});

  useEffect(() => {
    const abHeight = displayAB ? document?.querySelector(".ab_container")?.offsetHeight || 0 : 0;
    const headerHeight = document?.querySelector("header")?.offsetHeight;

    setOffset({
      position: "sticky",
      top: isHeader ? `${abHeight}px` : `calc(${headerHeight}px + ${abHeight}px + ${topOffset})`,
    });
  }, [displayAB, isHeader, topOffset]);

  if (!isBrowser) {
    return null;
  }

  return offset;
}

export default useSticky;
