import { useContext } from "react";
import { PropTypes } from "prop-types";

import { DiscountContext } from "../../context/DiscountProvider";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import { Popup } from "../Popup";
import { ALLink as Link } from "../ALComponents";
import ABPopupFreeGift from "../AnnouncementBarOld/ABPopupFreeGift";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import ShippingInformation from "./announcementBarPopup/ShippingInformation";
import ShippingDeadlines from "./announcementBarPopup/ShippingDeadlines";

import IconClose from "../icons/svgs/close.svg";

import * as Styles from "./announcementBarPopup/styles.module.scss";

function AnnouncementBarPopup({ open, onClose, backgroundColor, popup }) {
  const { discountInfo } = useContext(DiscountContext);
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();

  const { textColor, title, backgroundImage } = popup;

  if (!discountInfo) {
    return null;
  }

  return (
    <Popup className="popup_ab" open={open} onClose={onClose}>
      <div style={{ backgroundColor, color: textColor }} data-testid="ab-popup">
        <button
          onClick={onClose}
          className={Styles.close_button}
          type="button"
          data-testid="close-button"
        >
          <IconClose width="30" fill={textColor} />
        </button>
        <div
          className={`${Styles.popup_container} ${
            backgroundImage?.title !== "empty" ? Styles.popup_container_image : ""
          }`}
        >
          {backgroundImage?.title !== "empty" && (
            <div className={Styles.popup_container_custom_image}>
              <img src={backgroundImage?.url} alt={backgroundImage?.title} />
              <Link
                className="al_p--bold"
                to="/pages/promo-sale"
                style={{ color: textColor }}
                onClick={onClose}
              >
                <u>{dict.get("Exclusions Apply")}</u>
              </Link>
            </div>
          )}
          {backgroundImage?.title === "empty" && title === "custom_popup_free_gift" && (
            <ABPopupFreeGift
              discount={discountInfo}
              textColor={textColor}
              data={JSON.parse(popup?.deadlines?.internal?.content)}
            />
          )}
          {backgroundImage?.title === "empty" && title !== "custom_popup_free_gift" && (
            <>
              <h2 className={`al_h4 ${Styles.popup_container_subtitle}`}>
                {popup && title ? `${dict.get(popup.title)}` : ""}
              </h2>

              {discountInfo.type === DISCOUNT_TYPES.QUANTITY_PERCENT &&
                discountInfo.discounts?.map((discount, index) => {
                  const isLastItem = index === discountInfo.discounts?.length - 1;
                  return (
                    <div className="al_h1" key={`promo_${index}`}>
                      {isMobile
                        ? `${discount[1]}% off ${discount[0]}${isLastItem ? "+" : ""} item${
                            discount[0] > 1 ? "s" : ""
                          }${isLastItem ? "" : ","}`
                        : dict.get(
                            "Buy {0}, get {1}% off",
                            `${discount[0]}${isLastItem ? "+" : ""}`,
                            discount[1]
                          )}
                    </div>
                  );
                })}

              <ShippingDeadlines popup={popup} />

              <ShippingInformation textColor={textColor} />

              <p className="al_p">
                {dict.get(
                  "Code automatically applied at checkout. Limited-time online sale. Conditions apply."
                )}
                <Link
                  className="al_p--bold"
                  to="/pages/promo-sale"
                  style={{ color: textColor }}
                  onClick={onClose}
                >
                  <u>{dict.get("Read more")}</u>
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
}

AnnouncementBarPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  backgroundColor: PropTypes.string,
  popup: PropTypes.shape({
    textColor: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    deadlines: PropTypes.shape(),
  }),
};

export default AnnouncementBarPopup;
