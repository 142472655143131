import PropTypes from "prop-types";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";

import Timer from "../../Timer";

import * as Styles from "./announcementBarTimer/styles.module.scss";

function AnnouncementBarTimer({ timer }) {
  const timerStyle = {
    borderColor: useResponsiveWithHydrationFix(
      timer?.borderColor?.mobile,
      timer?.borderColor?.desktop
    ),
    backgroundColor: useResponsiveWithHydrationFix(
      timer?.backgroundColor?.mobile,
      timer?.backgroundColor?.desktop
    ),
    textColor: useResponsiveWithHydrationFix(timer?.textColor?.mobile, timer?.textColor?.desktop),
  };

  return (
    <div
      style={{
        borderColor: timer.borderColor.title !== "null" ? timerStyle.borderColor : "",
        color: timer.textColor.title !== "null" ? timerStyle.textColor : "",
        backgroundColor: timer.backgroundColor.title !== "null" ? timerStyle.backgroundColor : "",
      }}
      className={Styles.timer}
      data-testid="timer"
    >
      <Timer
        timerCountdown={timer.timerCountdown}
        timerStartDate={timer.timerStartDate}
        timerEndDate={timer.timerEndDate}
      />
    </div>
  );
}

AnnouncementBarTimer.propTypes = {
  timer: PropTypes.shape({
    enabled: PropTypes.bool,
    backgroundColor: PropTypes.shape({
      title: PropTypes.string,
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    borderColor: PropTypes.shape({
      title: PropTypes.string,
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    textColor: PropTypes.shape({
      title: PropTypes.string,
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
  }),
};
export default AnnouncementBarTimer;
