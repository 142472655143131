// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--81cec";
export var bold = "styles-module--bold--9a92c";
export var byob = "styles-module--byob--bb90f";
export var center = "styles-module--center--53eb5";
export var cp = "styles-module--cp--35634";
export var cp_ads = "styles-module--cp_ads--5fced";
export var cp_optional_products = "styles-module--cp_optional_products--2f665";
export var discount_text = "styles-module--discount_text--20c98";
export var discount_text_right = "styles-module--discount_text_right--114e3";
export var floating__discount_text = "styles-module--floating__discount_text--19513";
export var floating__price_container_percentage = "styles-module--floating__price_container_percentage--5f3c4";
export var h1 = "styles-module--h1--9946f";
export var h1Bold = "styles-module--h1--bold--fbe67";
export var h1Uc = "styles-module--h1--uc--9a1cd";
export var h2 = "styles-module--h2--e086c";
export var h2Bold = "styles-module--h2--bold--b8c3a";
export var h2Uc = "styles-module--h2--uc--0e5e6";
export var h3 = "styles-module--h3--23c04";
export var h3Bold = "styles-module--h3--bold--f60c0";
export var h3Uc = "styles-module--h3--uc--7a81d";
export var h4 = "styles-module--h4--ace4e";
export var h4Bold = "styles-module--h4--bold--afe61";
export var h4Uc = "styles-module--h4--uc--70a4e";
export var linkUnderline = "styles-module--link--underline--bd280";
export var microtext = "styles-module--microtext--681ff";
export var microtextBold = "styles-module--microtext--bold--1a07d";
export var microtextUc = "styles-module--microtext--uc--369a4";
export var nanotext = "styles-module--nanotext--12a37";
export var price_container = "styles-module--price_container--03b08";
export var price_container__cp = "styles-module--price_container__cp--7c6bf";
export var price_container__luxe = "styles-module--price_container__luxe--4eaf6";
export var price_container__luxeIcon = "styles-module--price_container__luxe-icon--0d3c5";
export var price_container__luxeIconInfo = "styles-module--price_container__luxe-icon-info--d0dfa";
export var price_container__luxePrice = "styles-module--price_container__luxe-price--1428a";
export var price_container__pdp_quick_upsell = "styles-module--price_container__pdp_quick_upsell--5d672";
export var price_container__product_carousel = "styles-module--price_container__product_carousel--4da66";
export var price_container__side_cart_upsell = "styles-module--price_container__side_cart_upsell--22d29";
export var price_container_before = "styles-module--price_container_before--75538";
export var price_container_final = "styles-module--price_container_final--6a330";
export var price_container_floating = "styles-module--price_container_floating--d6bc9";
export var price_container_full = "styles-module--price_container_full--8f2bd";
export var price_container_full_cp = "styles-module--price_container_full_cp--04e37";
export var price_container_full_floating = "styles-module--price_container_full_floating--0ec30";
export var price_container_full_has_discount = "styles-module--price_container_full_has_discount--f9b78";
export var price_container_full_has_discount_right = "styles-module--price_container_full_has_discount_right--97f88";
export var price_container_luxe = "styles-module--price_container_luxe--699d0";
export var price_container_pdp = "styles-module--price_container_pdp--147c2";
export var price_container_percentage = "styles-module--price_container_percentage--b9edd";
export var price_container_percentage__productCollection = "styles-module--price_container_percentage__product-collection--0e9bb";
export var prices_cp = "styles-module--prices_cp--d2fcd";
export var prices_pdp = "styles-module--prices_pdp--c3c03";
export var prices_pdp_ads = "styles-module--prices_pdp_ads--8e492";
export var search = "styles-module--search--79c2e";
export var skeletonWrapper = "styles-module--skeleton-wrapper--be47d";
export var strikethrough = "styles-module--strikethrough--75dc5";
export var strikethroughBold = "styles-module--strikethrough--bold--9e8db";
export var strikethroughLg = "styles-module--strikethrough--lg--c7f23";
export var strikethroughSm = "styles-module--strikethrough--sm--0cdda";
export var subtext = "styles-module--subtext--dac73";
export var subtextBold = "styles-module--subtext--bold--b3dde";
export var subtextUc = "styles-module--subtext--uc--edc42";
export var text = "styles-module--text--f9963";
export var textBold = "styles-module--text--bold--92385";
export var textUc = "styles-module--text--uc--64698";
export var titleL = "styles-module--title-l--76f0d";
export var titleM = "styles-module--title-m--0ef3d";
export var titleS = "styles-module--title-s--3d73d";
export var titleXl = "styles-module--title-xl--ececd";
export var titleXs = "styles-module--title-xs--8b193";
export var titleXxs = "styles-module--title-xxs--f5cae";
export var uc = "styles-module--uc--bdfaf";
export var underline = "styles-module--underline--38bb2";
export var wishlist = "styles-module--wishlist--b60dc";