/**
 * Check if sales (discounts) apply to the product based on the product tags.
 * if no sales apply to the product, return true
 * @param {string[]} productTags
 * @param {Object[]} discounts
 * @returns {boolean}
 */
export default function useIsBannedProduct(productTags, discounts) {
  if (!productTags || productTags.length === 0) return false;

  return !discounts.some(({ matchType, matchTags = [] }) => {
    if (matchType === "include") {
      return matchTags.some((tag) => productTags.includes(tag));
    }
    if (matchType === "exclude") {
      return matchTags.every((tag) => !productTags.includes(tag));
    }
    return false;
  });
}
