// extracted by mini-css-extract-plugin
export var popup_freegift = "ABPopupFreeGift-module--popup_freegift--3a684";
export var popup_freegift_condition = "ABPopupFreeGift-module--popup_freegift_condition--95ccd";
export var popup_freegift_container = "ABPopupFreeGift-module--popup_freegift_container--582aa";
export var popup_freegift_container_details = "ABPopupFreeGift-module--popup_freegift_container_details--be165";
export var popup_freegift_container_image = "ABPopupFreeGift-module--popup_freegift_container_image--a10d0";
export var popup_freegift_container_image_block = "ABPopupFreeGift-module--popup_freegift_container_image_block--08e4a";
export var popup_freegift_container_img = "ABPopupFreeGift-module--popup_freegift_container_img--ab154";
export var popup_freegift_container_price = "ABPopupFreeGift-module--popup_freegift_container_price--604fd";
export var popup_freegift_container_title = "ABPopupFreeGift-module--popup_freegift_container_title--388cc";
export var popup_freegift_subtitle = "ABPopupFreeGift-module--popup_freegift_subtitle--b4b9b";
export var popup_freegift_title = "ABPopupFreeGift-module--popup_freegift_title--ce167";