import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";

import { withALErrorBoundary } from "../../../../../helpers/ErrorBoundary/ALErrorBoundary";
import { usePriceInfo, useCurrentCountryCode } from "../../../../../hooks/usePrices";
import { ALTag } from "../../../../ALComponents";
import ALShopifyImage from "../../../../al_components/ALShopifyImage";
import PriceContainer from "../../../../PriceContainer";
import useApplyLuxeDiscountToProductPrice from "../../../../../hooks/useApplyLuxeDiscountToProductPrice";

import { Country } from "../../../../../constants/Country";
import { ComponentType } from "../../../../../constants/ComponentType";

import * as Styles from "./productElement/styles.module.scss";

function ProductElement({ product, isSelected = false, onToggle }) {
  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [product?.node.handle],
    tags: { [product?.node.handle]: product?.node?.tags },
    shouldFetchPrice: true,
  });
  const applyLuxeDiscountToProductPrice = useApplyLuxeDiscountToProductPrice();
  const countryCode = useCurrentCountryCode();

  if (!product) {
    return null;
  }

  const isPreOrder = product?.contentful?.isPreOrder;
  const hasNoVariantsAvailable = product.node.variants?.every(
    (x) => x.availableForSale === true && x.inventoryPolicy !== "DENY"
  );
  const shouldDisplayPreOrder = isPreOrder && countryCode === Country.US && hasNoVariantsAvailable;

  const images = (product.node?.images || []).slice(0, 2);

  return (
    <div
      className={Styles.container}
      onClick={() =>
        onToggle(product.node.handle, {
          ...prices,
          priceLuxe: applyLuxeDiscountToProductPrice(prices?.finalPrice, product.node.tags),
        })
      }
      data-testid="product-element"
    >
      <div className={Styles.imageContainer}>
        <ALTag
          product={product}
          componentType={ComponentType.CP_OPTIONAL_PRODUCTS}
          priceInfo={prices}
          shouldDisplayPreOrder={shouldDisplayPreOrder}
        />
        {images && (
          <>
            <div className={Styles.desktopImage}>
              {images.map((image, i) => (
                <ALShopifyImage
                  key={image?.url}
                  url={image?.url}
                  width={190}
                  height={190}
                  alt={image?.altText}
                  className={i === 0 ? "" : Styles.hoverImage}
                />
              ))}
            </div>

            <div className={Styles.mobileImage}>
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                slidesPerGroup={1}
                loop
                pagination={{
                  clickable: true,
                  modifierClass: "product_element_pagination",
                  bulletClass: "product_element_bullet",
                  bulletActiveClass: "product_element_bullet--active",
                }}
              >
                {images.map((image) => (
                  <SwiperSlide key={image?.url}>
                    <ALShopifyImage
                      url={image?.url}
                      width={190}
                      height={190}
                      alt={image?.altText}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        )}

        <input className={Styles.checkbox} type="checkbox" checked={isSelected} readOnly />
      </div>
      <div className={Styles.bottomContainer}>
        <div className={Styles.bottomTopContainer}>
          <h4 className="subtext subtext--bold subtext--uc m-0 mt-10 pr-5">
            {product.node.title.split(" - ")[1]}
          </h4>
          <PriceContainer
            productId={product.node.id}
            prices={prices}
            pricesLoading={pricesLoading}
            tags={product.node.tags}
            componentType={ComponentType.CP_OPTIONAL_PRODUCTS}
          />
        </div>
        <p className="microtext dark-grey mt-5">{product.node.title.split(" - ")[0]}</p>
      </div>
    </div>
  );
}

ProductElement.propTypes = {
  product: PropTypes.shape({
    node: PropTypes.shape({
      handle: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string,
      category: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          altText: PropTypes.string,
        })
      ),
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  isSelected: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default withALErrorBoundary({
  name: "ProductElement",
  priority: "P3",
})(ProductElement);
