import { useContext } from "react";
import { PropTypes } from "prop-types";
import { utcToZonedTime } from "date-fns-tz";
import { add, isBefore } from "date-fns";

import { CartContext } from "../context/CartProvider";
import { roundToTheHalf } from "../context/helpers";
import { getShipByDate } from "../helpers/product";
import { applyIgPrices } from "../helpers/intelligems";
import { PREORDER_TIMEZONE } from "../constants/Common";

import TAG from "../constants/Tag";
import { ComponentType } from "../constants/ComponentType";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import "./productMention/styles.scss";

/**
 *
 * In case of pre-order products, text is displayed only if preOrderShippingDate is
 * 4 days before the customDate set in Contentful.
 *
 * If customDate is null then text is displayed without being conditioned by date.
 *
 */

function shouldDisplayGlobalProductMention(text, isPreOrder, preOrderShippingDate, customDate) {
  let showGlobalProductMention = text && text !== "null";
  if (isPreOrder && customDate) {
    const customDateCurrent = utcToZonedTime(`${customDate}T00:00:00Z`, PREORDER_TIMEZONE);
    const preOrderShippingDateCurrent = utcToZonedTime(
      `${preOrderShippingDate}T23:59:59Z`,
      PREORDER_TIMEZONE
    );
    const estimatedDeliveryDays = { days: 4 };
    const computedArrivalDate = add(preOrderShippingDateCurrent, estimatedDeliveryDays);
    showGlobalProductMention =
      showGlobalProductMention && isBefore(computedArrivalDate, customDateCurrent);
  }
  return showGlobalProductMention;
}

/**
 * Order of priority for product mention
 * 1. Last Chance/ Ships by for pre-order products
 * 2. Global Product Mention (coming from Contentful, ex. Arrives before...)
 */

function ProductMention({ prices, product, globalProductMention, componentType, discountsInfo }) {
  const giftDiscount =
    (discountsInfo || []).find((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE) || null;
  const { itemsInCart } = useContext(CartContext);
  const { hasDiscount, discountGiven } = applyIgPrices(product.id, "", prices);

  let showGlobalText = true;
  let text;

  let prefix = "";
  if (product.type === "Bundle") {
    prefix = "Bundle Savings - ";
  }
  if (product.tags?.includes(TAG.FINAL_SALE)) {
    prefix = "Last Chance - ";
  }

  if (hasDiscount && discountGiven) {
    text = `${prefix}${roundToTheHalf(discountGiven)}% OFF`;
    showGlobalText = false;
  }

  if (
    product.isPreOrder &&
    (componentType === ComponentType.SEARCH || componentType === ComponentType.CP)
  ) {
    text = `Ships by: ${getShipByDate(product.preOrderShippingDate)}`;
    showGlobalText = false;
  }

  if (globalProductMention?.text && showGlobalText) {
    const showGlobalProductMention = shouldDisplayGlobalProductMention(
      globalProductMention.text,
      product.isPreOrder,
      product.preOrderShippingDate,
      globalProductMention.arrivalDate
    );

    if (showGlobalProductMention) {
      text = globalProductMention.text;
    }
  }

  if (giftDiscount && componentType === ComponentType.CP) {
    const { matchTags, matchType } = giftDiscount;
    const minEligibleQuantity = parseInt(giftDiscount?.freegift?.minEligibleQuantity, 10);
    if (minEligibleQuantity - 1 === itemsInCart) {
      text = "Add 1 More Item for a Free Gift";
    } else {
      text = `Buy ${minEligibleQuantity}+ Items, Get a Free Gift`;
    }
    text =
      matchTags.some((tag) => product.tags.includes(tag)) === (matchType === "exclude") ? "" : text;
  }

  if (text) {
    return <p className={`product_mention__${componentType?.toLowerCase()} poppy`}>{text}</p>;
  }

  return null;
}

ProductMention.propTypes = {
  // prices: PropTypes.object,
  product: PropTypes.object,
  // globalProductMention: PropTypes.object,
  componentType: PropTypes.string,
  discountsInfo: PropTypes.array,
};

export default ProductMention;
