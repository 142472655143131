import { useStaticQuery, graphql } from "gatsby";

import { useMemo } from "react";
import { edgesToArray } from "../context/helpers";
import { useCurrentLang } from "./useLocalizedCartInfo";
import { useCurrentCountryCode } from "./usePrices";

function useAnnouncementBar() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAnnouncementBarContainer {
        edges {
          node {
            node_locale
            name
            countries {
              code
              country
            }
            backgroundColor {
              mobile
              desktop
            }
            mobileContent {
              ...ContentfulAnnouncementBarSectionFields
            }
            desktopContent {
              ...ContentfulAnnouncementBarSectionFields
            }
            popup {
              title
              subtitle
              deadlines {
                internal {
                  content
                }
              }
              backgroundImage {
                title
                url
              }
              textColor
            }
          }
        }
      }
    }
  `);

  const lang = useCurrentLang();
  const countryCode = useCurrentCountryCode();

  let fallbackABContent = "";
  let abContent = "";

  if (data?.allContentfulAnnouncementBarContainer) {
    const allAnnouncementBar = edgesToArray(data.allContentfulAnnouncementBarContainer);

    fallbackABContent = allAnnouncementBar.find(
      (n) => n.node_locale === lang && n?.name === "FALLBACK"
    );

    const defaultAnnouncementBar = allAnnouncementBar.find(
      (n) => n?.countries?.some((c) => c.code === "RoW") && n.node_locale === lang
    );

    const localAnnouncementBar = allAnnouncementBar.find(
      (n) => n?.countries?.some((c) => c.code === countryCode) && n.node_locale === lang
    );

    abContent = localAnnouncementBar ?? defaultAnnouncementBar;
  }

  const contentAB = abContent || fallbackABContent;

  const abData = useMemo(
    () => ({
      backgroundColor: contentAB?.backgroundColor,
      mobileContent: contentAB?.mobileContent,
      desktopContent: contentAB?.desktopContent,
      popup: contentAB?.popup,
    }),
    [
      contentAB?.backgroundColor,
      contentAB?.mobileContent,
      contentAB?.desktopContent,
      contentAB?.popup,
    ]
  );

  if (!data) {
    return null;
  }

  return abData;
}

export default useAnnouncementBar;
