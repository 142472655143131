import { PropTypes } from "prop-types";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";

import "./itemPrice/styles.scss";

function ItemPrice({ variant, isFreeGift = false, isLuxe = false }) {
  const formatCurrency = useCurrentFormatCurrency();

  if (!variant.final_price || !variant.full_price) {
    return null;
  }

  const appliedFinalPrice = isLuxe ? variant.final_price_luxe : variant.base_price;
  if (appliedFinalPrice < variant.full_price || isFreeGift) {
    return (
      <div className="item_price_container">
        <div className="item_price_strike">
          <p className="item_price--fullPrice">
            {isFreeGift ? "FREE" : formatCurrency(appliedFinalPrice)}
          </p>
          <p className="item_price--finalPrice">{formatCurrency(variant.full_price)}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="item_price">
      <p className="item_price--finalPrice">{formatCurrency(appliedFinalPrice)}</p>
    </div>
  );
}

ItemPrice.propTypes = {
  variant: PropTypes.shape({
    final_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    full_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    final_price_luxe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isFreeGift: PropTypes.bool,
  isLuxe: PropTypes.bool,
};

export default ItemPrice;
