import { useContext } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import { CartContext } from "../../context/CartProvider";

import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useShippingInfo } from "../../hooks/useShippingInfo";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import useSalesColors from "../../hooks/useSalesColors";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";
import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import { applyCartDiscounts } from "../cartDiscounts";
import { ComponentType } from "../../constants/ComponentType";
import { parseText } from "../../helpers/common";
import AnnouncementBarTimer from "./section/AnnouncementBarTimer";
import QuantityPercentProgressTracker from "../al_components/QuantityPercentProgressTracker";
import QuantityPercentProgressTrackerGift from "../al_components/QuantityPercentProgressTrackerGift";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import * as Styles from "./section/styles.module.scss";

SwiperCore.use([Autoplay]);

function Section({
  textArray,
  addShippingThreshold,
  textNoThreshold,
  textPosition,
  enableRotatingText,
  timer,
  displayLuxeProgressBar,
}) {
  const salesColors = useSalesColors();
  const { isMobile } = useDeviceDetect();
  const shippingInfo = useShippingInfo();
  const { cart } = useContext(CartContext);
  const discountsInfo = useDiscountsInfo();
  const hasGiftDiscount =
    discountsInfo.findIndex((s) => s.type === DISCOUNT_TYPES.GIFT_WITH_PURCHASE) !== -1;
  const sideCartInfo = applyCartDiscounts(cart.lines, discountsInfo);

  const formatCurrency = useCurrentFormatCurrency();

  const shippingTreshold = addShippingThreshold ? shippingInfo?.freeShippingThreshold : null;

  const sectionStyle = {
    fontWeight: textArray[0].fontWeight?.toLowerCase(),
    textColor: useResponsiveWithHydrationFix(
      textArray[0].textColor?.mobile,
      textArray[0].textColor?.desktop
    ),
  };

  const showSection = textArray[0].text !== "null";

  if (enableRotatingText) {
    return (
      <div
        className={Styles.section}
        style={{
          textAlign: textPosition,
        }}
      >
        <Swiper
          enabled
          spaceBetween="80"
          slidesPerView="1"
          centeredSlides
          speed="5000"
          loop
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false,
          }}
        >
          {textArray.map((text, i) => (
            <SwiperSlide
              key={`${text.text}-${i}`}
              style={{
                fontWeight: text.fontWeight,
                color: isMobile ? text.textColor.mobile : text.textColor.desktop,
                justifySelf: isMobile ? "inherit" : textPosition?.toLowerCase(),
              }}
              className={Styles.text}
              data-testid="swiper-slide"
            >
              {parseText(text.text)}
            </SwiperSlide>
          ))}
        </Swiper>
        {timer?.enabled && <AnnouncementBarTimer timer={timer} />}
      </div>
    );
  }

  return (
    <div
      className={Styles.section}
      style={{
        justifyContent: textPosition,
        textAlign: textPosition,
        visibility: showSection ? "inherit" : "hidden",
        width: "100%",
        height: "40px",
      }}
    >
      {displayLuxeProgressBar && hasGiftDiscount && sideCartInfo?.finalAmount > 0 ? (
        <div className={Styles.section_luxe}>
          <QuantityPercentProgressTrackerGift
            eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
            componentType={ComponentType.ANNOUNCEMENT_BAR}
            isLuxe
            saleColors={salesColors}
            isTextAbove={false}
          />
        </div>
      ) : displayLuxeProgressBar && sideCartInfo.nonBannedItemQuantity > 0 ? (
        <div className={Styles.section_luxe}>
          <QuantityPercentProgressTracker
            eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
            componentType={ComponentType.ANNOUNCEMENT_BAR}
            isLuxe
            saleColors={salesColors}
            isTextAbove={false}
            discountInfoMode="luxe_only"
          />
          <p>
            <span>*only with</span>
            <br />
            <span>AL Luxe</span>
          </p>
        </div>
      ) : (
        <div
          className={Styles.section__text}
          style={{
            fontWeight: sectionStyle.fontWeight,
            color: sectionStyle.textColor,
          }}
        >
          {textArray[0].prefix && textArray[0].prefix !== "null" && (
            <span>{textArray[0].prefix}</span>
          )}
          <p>
            {shippingTreshold === null && parseText(textArray[0].text)}
            {shippingTreshold === 0 && textNoThreshold}
            {shippingTreshold > 0 && (
              <span>{`${parseText(textArray[0].text)} ${formatCurrency(shippingTreshold)}`}</span>
            )}
          </p>
        </div>
      )}
      {timer?.enabled && <AnnouncementBarTimer timer={timer} />}
    </div>
  );
}

export default Section;

Section.propTypes = {
  textArray: PropTypes.arrayOf(
    PropTypes.shape({
      textColor: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
      borderColor: PropTypes.shape({
        mobile: PropTypes.string,
        desktop: PropTypes.string,
      }),
      fontWeight: PropTypes.string,
      prefix: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  addShippingThreshold: PropTypes.bool,
  textNoThreshold: PropTypes.string,
  textPosition: PropTypes.string,
  enableRotatingText: PropTypes.bool,
  timer: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  displayLuxeProgressBar: PropTypes.bool,
};
