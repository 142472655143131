import { useContext } from "react";
import PropTypes from "prop-types";

import { GeoContext } from "../../context/GeoProvider";
import { getLocalizedPricesWithDiscount } from "../../context/helpers";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useExclusionTags } from "../../hooks/useExclusionTags";
import { ComponentType } from "../../constants/ComponentType";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import RingSizeGuidePopUp from "./RingSizeGuidePopUp";

import IconArrow from "../icons/svgs/arrow.svg";

function VariantSelector({
  onChange,
  options,
  handle,
  country,
  productType,
  variants,
  currentSelection,
  componentType,
  stepPrefix = "",
  tags = [],
}) {
  const { gePriceDetails, isLoadingGE } = useContext(GeoContext);
  const discountsInfo = useDiscountsInfo();
  const dict = useLocalizedSentenceDict();
  const nameOptionsValue = options.values[0]; //  == "Default Title"
  const GEDetail = gePriceDetails;
  const exclusionTags = useExclusionTags();

  if (nameOptionsValue === "Default Title") {
    return null;
  }

  if (country !== "US" && handle === "gift-card") {
    return null;
  }

  const optionName = options.name.toLowerCase();

  let titleDropDown = dict.get(options.name);

  if (optionName === "number") {
    titleDropDown = dict.get("Choose a number");
  }
  if (optionName === "sign") {
    titleDropDown = dict.get("Choose your sign");
  }
  if (optionName === "birthstone") {
    titleDropDown = dict.get("Choose your birthstone");
  }

  const dropDownOptions = ["letter", "number", "sign", "birthstone"];

  return (
    <div className="pdp_details_variant">
      {dropDownOptions.includes(optionName) ? (
        <>
          <p className="subtext subtext--bold subtext--uc">
            {stepPrefix}
            {titleDropDown}
          </p>
          <div className="pdp_details_variant_letter_container">
            <select
              className="pdp_details_variant_letter variant-input-wrap"
              onChange={onChange}
              name={options.name}
              defaultValue={currentSelection[options.name]}
            >
              {variants.map((variant) => (
                <option
                  key={`variant_select_${variant?.id}`}
                  className={`variant_select variant_select_${variant?.title} ${
                    !variant?.availableForSale ? "variant_select_soldout" : ""
                  }`}
                  value={variant?.title}
                >
                  {!variant?.availableForSale
                    ? `${variant?.title} - ${dict.get("join the list")}`
                    : variant?.title}
                </option>
              ))}
            </select>
            <IconArrow
              fill="#2D2927"
              width="13"
              height="13"
              className="pdp_details_variant_letter_arrow"
            />
          </div>
        </>
      ) : (
        <>
          <p className="pdp_details_variant_title">
            {stepPrefix}
            {dict.get(
              optionName === "size" && productType === "Rings" ? "Ring Size" : options.name
            )}
          </p>
          {options.name === "Size" && productType === "Rings" ? <RingSizeGuidePopUp /> : null}
          <fieldset
            onChange={onChange}
            className="variant-input-wrap"
            name={options.name}
            key={options.id}
          >
            {options.values.map((value, i) => {
              const variant = variants[i];
              let priceStr = null; // gift card
              if (
                optionName === "amount" &&
                (discountsInfo == null ||
                  discountsInfo.length === 0 ||
                  GEDetail == null ||
                  isLoadingGE)
              ) {
                return null;
              }
              if (
                optionName === "amount" &&
                discountsInfo &&
                discountsInfo.length > 0 &&
                GEDetail
              ) {
                if (GEDetail.CurrencyCode !== "USD") {
                  try {
                    const prices = getLocalizedPricesWithDiscount({
                      compareAtPrice: null,
                      price: parseFloat(value.replace("$", "")),
                      discounts: discountsInfo,
                      geDetails: GEDetail,
                      shouldUseDiscount:
                        [ComponentType.BYOB, ComponentType.BYOB_PDP].indexOf(componentType) ===
                          -1 && !tags.some((r) => exclusionTags.includes(r)),
                    });
                    priceStr = `${GEDetail.CurrencySymbol}${prices.full_price}`;
                  } catch (error) {
                    console.log(error, " => error");
                  }
                }
              }

              return (
                <span
                  className={`variant-input-wrap-${optionName} variant-input-options-${options.values.length}`}
                  key={variant.id}
                >
                  <input
                    className="variant-input"
                    name={options.name}
                    type="radio"
                    id={`${options.name}-${value}-${componentType}`}
                    value={value}
                  />
                  <label
                    className={`variant-label ${
                      value === currentSelection[options.name] ? "variant-label--selected" : ""
                    } ${variant.availableForSale ? "" : "variant-label--disabled"}`}
                    htmlFor={`${options.name}-${value}-${componentType}`}
                  >
                    {optionName === "amount" ? priceStr || value : `${value}`}
                  </label>
                </span>
              );
            })}
          </fieldset>
        </>
      )}
    </div>
  );
}

VariantSelector.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  handle: PropTypes.string,
  country: PropTypes.string,
  productType: PropTypes.string,
  stepPrefix: PropTypes.string,
  variants: PropTypes.array,
  currentSelection: PropTypes.object,
  componentType: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withALErrorBoundary({
  name: "VariantSelector",
  priority: "P1",
})(VariantSelector);
