import { useCurrentFormatCurrency } from "../../hooks/usePrices";

import "./sideCartTotalTieredDiscount/styles.scss";

function SideCartTotalTieredDiscount({
  estimatedTotal,
  fullAmount,
  showLineThrough,
  hasFreeShipping,
  shippingPrice,
}) {
  const formatCurrency = useCurrentFormatCurrency();

  return (
    <p className="sidecart_total_container_tiered_discount subtext">
      {/* The key below is used to prevent a Safari-only rendering issue https://stackoverflow.com/a/55050203 */}
      {estimatedTotal < fullAmount ? (
        <span key={`sidecart_total_${estimatedTotal}`}>
          {showLineThrough ? (
            <span className="sidecart_total_content_tiered_discount--withLineThrough">
              <span className="strikethrough strikethrough--bold">
                {formatCurrency(fullAmount)}
              </span>
              <span className="sidecart_total_content_tiered_discount subtext subtext--uc subtext--bold">
                {formatCurrency(estimatedTotal)}
              </span>
            </span>
          ) : (
            <span className="sidecart_total_content_tiered_discount--withoutLineThrough">
              <span className="subtext subtext--uc subtext--bold">
                {formatCurrency(estimatedTotal)}
              </span>
            </span>
          )}
        </span>
      ) : (
        <span key={`classic_${fullAmount}`} className="subtext subtext--uc subtext--bold">
          {formatCurrency(hasFreeShipping ? estimatedTotal : fullAmount + shippingPrice)}
        </span>
      )}
    </p>
  );
}

export default SideCartTotalTieredDiscount;
