import { isBrowser } from "../helpers";

export function twPageView() {
  const hasTw = isBrowser && typeof window.TriplePixel !== "undefined";
  if (!hasTw) {
    return false;
  }
  window.TriplePixel("pageLoad");
  return true;
}

export function twContact(email) {
  const hasTw = isBrowser && typeof window.TriplePixel !== "undefined";
  if (!hasTw) {
    return false;
  }
  window.TriplePixel("Contact", { email });
  return true;
}

export function twAddToCart({ productId, variantId, qty }) {
  const hasTw = isBrowser && typeof window.TriplePixel !== "undefined";
  if (!hasTw) {
    return false;
  }
  window.TriplePixel("AddToCart", {
    item: productId,
    q: qty,
    v: variantId,
  });
  return true;
}
