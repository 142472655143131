import PropTypes from "prop-types";
import { useContext } from "react";
import { formatISO, parse } from "date-fns";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { roundNumber } from "../../context/helpers";
import { ShippingDelayContext } from "../../context/ShippingDelayProvider";
import { getShipByDate, getDaysUntilShipping } from "../../helpers/product";
import useDiscountsInfo from "../../hooks/useDiscountsInfo";

import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import LuxeIconCart from "../icons/svgs/luxe-cart.svg";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";
import SideCartTotalTieredDiscount from "./SideCartTotalTieredDiscount";

function PriceSummary({ sideCartInfo, shippingInfo, countryCode, isLuxe }) {
  const discountsInfo = useDiscountsInfo();
  const { deliveryDateFormatted, isShippingEstimationLoading, deliveryTimelinesFormatted } =
    useContext(ShippingDelayContext);

  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();

  const siteWideDiscountItemCount = sideCartInfo.nonBannedItemQuantity;
  const hasFreeShipping =
    (shippingInfo?.freeShippingThreshold &&
      sideCartInfo.finalAmount > shippingInfo.freeShippingThreshold) ||
    shippingInfo?.freeShippingThreshold === 0;

  const preOrderItems = sideCartInfo.lines.filter((lineItem) =>
    lineItem.attributes?.find(({ key, value }) => key === "_pre_order" && value === "true")
  );

  let deliveryDateToDisplay = `Estimated Delivery by: ${deliveryDateFormatted}`;
  let hasPreorder = false;
  // Show preorder date only if there is one item in the cart and that item is a preorder
  if (
    sideCartInfo.lines.filter(
      (l) =>
        ![HandleType.SUBSCRIPTION, HandleType.ORDER_PROTECTION].includes(
          l?.variant?.product?.handle
        )
    ).length === 1 &&
    preOrderItems.length === 1
  ) {
    const shipsByDateAttr =
      preOrderItems[0].attributes?.find(({ key }) => key === "_ships_by_pre_order")?.value || "";
    const shipsByDate = parse(shipsByDateAttr, "MMM d, yyyy", new Date());
    const daysUntilShipping = shipsByDate ? getDaysUntilShipping(shipsByDate) : 0;
    if (daysUntilShipping > 7) {
      hasPreorder = true;
      deliveryDateToDisplay = `Ships by: ${getShipByDate(
        formatISO(shipsByDate, { representation: "date" }),
        "MMM do"
      )}`;
    }
  }

  if (preOrderItems.length > 1) {
    deliveryDateToDisplay = "";
  }

  const tierDiscount = discountsInfo.find(({ type }) => type === DISCOUNT_TYPES.QUANTITY_PERCENT);

  let discountCodeText = "";

  if (!!tierDiscount && tierDiscount.thresholdConditionQuantity.length > 0) {
    const firstTierQuantityThreshold = tierDiscount.thresholdConditionQuantity[0];
    const lastTierQuantityThreshold =
      tierDiscount.thresholdConditionQuantity[tierDiscount.thresholdConditionQuantity.length - 1];
    const lastTierDiscountPercentage =
      tierDiscount.thresholdResultPercentage[tierDiscount.thresholdResultPercentage.length - 1];

    if (
      siteWideDiscountItemCount < lastTierQuantityThreshold &&
      siteWideDiscountItemCount >= firstTierQuantityThreshold
    ) {
      let appliedDiscountIndex = 0;
      for (let i = 0; i < tierDiscount.thresholdConditionQuantity.length; i += 1) {
        if (siteWideDiscountItemCount >= tierDiscount.thresholdConditionQuantity[i]) {
          appliedDiscountIndex = i;
        }
      }
      discountCodeText = `${tierDiscount.thresholdResultPercentage[appliedDiscountIndex]}% OFF`;
    }
    if (siteWideDiscountItemCount >= lastTierQuantityThreshold) {
      discountCodeText = `${lastTierDiscountPercentage}% OFF`;
    }
  }

  const shippingValueUS = (
    <span>
      <span className="sidecart_shipping_price">{formatCurrency(5.99)}</span>
      <span className="sidecart_shipping_free">FREE</span>
    </span>
  );

  let shippingValue = "";
  if (hasFreeShipping) {
    shippingValue = dict.get("FREE");
  } else if (countryCode === Country.US) {
    shippingValue = shippingValueUS;
  } else {
    shippingValue = formatCurrency(shippingInfo?.shippingPrice);
  }
  console.log(shippingValue, " => shippingValue");

  const hasBuyMoreSaveMoreDiscount =
    (isLuxe && (sideCartInfo.discountTieredOfferLuxe || 0) > 0) ||
    (!isLuxe && (sideCartInfo.discountTieredOffer || 0) > 0);
  const buyMoreSaveMoreAmount = isLuxe
    ? sideCartInfo.discountTieredOfferLuxe
    : sideCartInfo.discountTieredOffer;
  const estimatedTotal = roundNumber(
    isLuxe ? sideCartInfo.finalAmountLux : sideCartInfo.finalAmount
  );

  return (
    <div className="sidecart_total">
      {/* Subtotal */}
      <p className="sidecart_total_content subtext">
        <span>{dict.get("Subtotal")}</span>
        <span>{formatCurrency(sideCartInfo.fullAmount)}</span>
      </p>

      {/* AL Luxe */}
      {isLuxe && sideCartInfo.discountPercentOffLuxe > 0 && (
        <p className="sidecart_total_content subtext">
          <span className="sidecart_total_content--color mr--5">
            <LuxeIconCart
              style={{
                marginRight: "3px",
                marginBottom: "-3px",
              }}
            />
            <b>5% off</b> Solid Gold
          </span>
          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountPercentOffLuxe))}`}
          </span>
        </p>
      )}

      {/* Buy More, Save More */}
      {hasBuyMoreSaveMoreDiscount && (
        <p className="sidecart_total_content subtext" data-testid="sidecart_total_content_discount">
          <span>
            <span className="sidecart_total_content--color mr--5">
              {isLuxe && (
                <LuxeIconCart
                  style={{
                    marginRight: "3px",
                    marginBottom: "-3px",
                  }}
                />
              )}
              {dict.get("Buy More, Save More:")}
            </span>
            <span className="sidecart_total_content_title_discount">{discountCodeText}</span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(buyMoreSaveMoreAmount))}`}
          </span>
        </p>
      )}

      {/* Last Chance Discount */}
      {sideCartInfo.discountCompareAtPrice > 0 && (
        <p className="sidecart_total_content subtext">
          <span>
            <span className="sidecart_total_content--color mr--5">
              {dict.get("Last Chance Discount:")}
            </span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountCompareAtPrice))}`}
          </span>
        </p>
      )}

      {/* Guest Percent off first order */}
      {!isLuxe && sideCartInfo.discountPercentOff > 0 && (
        <p className="sidecart_total_content subtext">
          <span>
            <span className=" mr--5">
              <b>{discountsInfo.find((d) => d.type === "percent_off")?.value}% OFF</b>
              {dict.get(" first order")}
            </span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountPercentOff))}`}
          </span>
        </p>
      )}

      {/* Free gift Discount */}
      {sideCartInfo.discountFreeGift > 0 && (
        <p className="sidecart_total_content subtext">
          <span>
            <span className="sidecart_total_content--color mr--5">{dict.get("Free Gift")}</span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountFreeGift))}`}
          </span>
        </p>
      )}

      {/* Shipping */}
      {!hasFreeShipping && (
        <p className="sidecart_total_content subtext">
          <span>{dict.get("Shipping")}</span>
          <span>{formatCurrency(shippingInfo?.shippingPrice)}</span>
        </p>
      )}

      <p className="sidecart_total_content subtext">
        <span>
          <span className="text--bold mr--5">{dict.get("Total")}</span>
        </span>

        <SideCartTotalTieredDiscount
          estimatedTotal={estimatedTotal}
          fullAmount={sideCartInfo.fullAmount}
          hasFreeShipping={hasFreeShipping}
          shippingPrice={shippingInfo?.shippingPrice}
        />
      </p>

      <div className="sidecart_total_content">
        <p className="sidecart_total_content_shipping_title microtext">
          {deliveryDateToDisplay &&
            deliveryTimelinesFormatted &&
            !isShippingEstimationLoading &&
            (hasPreorder
              ? deliveryDateToDisplay
              : `${
                  hasFreeShipping ? "Free " : "Estimated "
                } Delivery ${deliveryTimelinesFormatted}`)}
        </p>
        {/*
        <span className={`${hasFreeShipping ? "sidecart_total_content_value_color" : ""}`}>
          {shippingValue}
        </span>
        */}
      </div>
    </div>
  );
}

PriceSummary.propTypes = {
  sideCartInfo: PropTypes.shape({
    lines: PropTypes.array,
    fullAmount: PropTypes.number,
    finalAmount: PropTypes.number,
    nonBannedItemQuantity: PropTypes.number,
    discountLuxe: PropTypes.number,
    discountTieredOffer: PropTypes.number,
    discountTieredOfferLuxe: PropTypes.number,
    discountCompareAtPrice: PropTypes.number,
    discountPercentOff: PropTypes.number,
    discountPercentOffLuxe: PropTypes.number,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    freeShippingThreshold: PropTypes.number,
    shippingPrice: PropTypes.number,
  }).isRequired,
  countryCode: PropTypes.string.isRequired,
  isLuxe: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceSummary",
  priority: "P1",
})(PriceSummary);
