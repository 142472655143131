import { useState } from "react";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import IconAdd from "../../icons/svgs/add.svg";
import IconMinus from "../../icons/svgs/minus.svg";

import * as Styles from "./shippingInformation/styles.module.scss";

function ShippingInformation({ textColor }) {
  const dict = useLocalizedSentenceDict();

  const [isDetailOpen, setDetailOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={`${Styles.popup_shipping_info_mobile}`}
        onClick={() => setDetailOpen(!isDetailOpen)}
        data-testid="shipping-info-button"
      >
        <p className={`al_p ${Styles.popup_shipping_info_mobile_title}`}>
          <span>*{dict.get("Important Shipping Information")}</span>
          {isDetailOpen ? (
            <IconMinus width="30" stroke={textColor} />
          ) : (
            <IconAdd width="30" fill={textColor} />
          )}
        </p>
        {isDetailOpen ? (
          <div className={`${Styles.popup_shipping_info_mobile_content}`}>
            <p className="al_p">
              {dict.get(
                "Orders may take longer than expected to arrive based on inclement weather, holidays, and/or carrier delays, and this is out of our control."
              )}
            </p>
          </div>
        ) : null}
      </button>
      <div className={`${Styles.popup_shipping_info_desktop}`}>
        <p className="al_p">
          *
          {dict.get(
            "Orders may take longer than expected to arrive based on inclement weather, holidays, and/or carrier delays, and this is out of our control."
          )}
        </p>
      </div>
    </>
  );
}

export default ShippingInformation;
