import Vimeo from "@u-wave/react-vimeo";
import PropTypes from "prop-types";
import ALSkeleton from "./ALSkeleton";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";

function ALVideo({
  children,
  videoId,
  customClasses,
  previewOnly,
  videoThumbnailData,
  videoUrl = null,
}) {
  const isHydrationComplete = useIsHydrationComplete();

  let video = (
    <Vimeo
      className={customClasses?.video}
      video={videoId}
      muted
      loop
      background
      autoplay
      dnt
      responsive
    />
  );

  if (videoUrl) {
    video = <video src={videoUrl} autoPlay muted loop playsInline />;

    if (previewOnly) {
      video = <video src={videoUrl} autoPlay={false} muted loop playsInline />;
    }
  }
  const videoContainer = useResponsiveWithHydrationFix(
    <div className="video_vimeo_content">{video}</div>,
    <div className={`video_vimeo_content ${customClasses?.vimeo_container || ""}`}>{video}</div>
  );
  const aspectRatio = useResponsiveWithHydrationFix(
    `${videoThumbnailData?.mobile?.width} / ${videoThumbnailData?.mobile?.height}`,
    `${videoThumbnailData?.desktop?.width} / ${videoThumbnailData?.desktop?.height}`
  );

  return (
    <div
      className={`video_vimeo_container ${customClasses?.container || ""}`}
      style={{
        aspectRatio: videoThumbnailData?.hasData ? aspectRatio : "inherit",
      }}
    >
      {children}
      {videoThumbnailData?.hasData ? (
        <>
          <img
            className={`${
              customClasses?.image_thumbnail || ""
            } video_vimeo_container_thumbnail--desktop`}
            src={videoThumbnailData.desktop.url}
            alt="video thumbnail"
          />
          <img
            className={`${
              customClasses?.image_thumbnail || ""
            } video_vimeo_container_thumbnail--mobile`}
            src={videoThumbnailData.mobile.url}
            alt="video thumbnail"
          />
        </>
      ) : (
        <div
          className={`${customClasses?.skeleton_container || ""}`}
          style={{
            aspectRatio: videoThumbnailData?.hasData ? aspectRatio : "inherit",
          }}
        >
          <div
            className={`${customClasses?.skeleton_image || ""}`}
            style={{
              aspectRatio: videoThumbnailData?.hasData ? aspectRatio : "inherit",
            }}
          >
            <ALSkeleton />
          </div>
        </div>
      )}
      <div className={customClasses?.video_container} />

      {isHydrationComplete && videoContainer}
    </div>
  );
}

ALVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  customClasses: PropTypes.shape({
    container: PropTypes.string,
    vimeo_container: PropTypes.string,
    image_thumbnail: PropTypes.string,
    video_container: PropTypes.string,
    video: PropTypes.string,
    skeleton_container: PropTypes.string,
    skeleton_image: PropTypes.string,
  }),
  previewOnly: PropTypes.bool,
  videoThumbnailData: PropTypes.shape({
    desktop: PropTypes.shape({
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    mobile: PropTypes.shape({
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    hasData: PropTypes.bool,
  }),
  videoUrl: PropTypes.string,
};

export default withALErrorBoundary({ name: "ALVideo", priority: "P4" })(ALVideo);
