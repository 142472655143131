// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--d1ebf";
export var bold = "styles-module--bold--cba43";
export var center = "styles-module--center--67e28";
export var h1 = "styles-module--h1--d6df1";
export var h1Bold = "styles-module--h1--bold--26273";
export var h1Uc = "styles-module--h1--uc--0658f";
export var h2 = "styles-module--h2--85db9";
export var h2Bold = "styles-module--h2--bold--4d826";
export var h2Uc = "styles-module--h2--uc--f1722";
export var h3 = "styles-module--h3--cf245";
export var h3Bold = "styles-module--h3--bold--61694";
export var h3Uc = "styles-module--h3--uc--2cc70";
export var h4 = "styles-module--h4--e908d";
export var h4Bold = "styles-module--h4--bold--beb78";
export var h4Uc = "styles-module--h4--uc--912fa";
export var linkUnderline = "styles-module--link--underline--3a607";
export var microtext = "styles-module--microtext--0cb97";
export var microtextBold = "styles-module--microtext--bold--a9be9";
export var microtextUc = "styles-module--microtext--uc--2d86f";
export var nanotext = "styles-module--nanotext--844ce";
export var socialProof__autoScroll = "styles-module--social-proof__auto-scroll--b9e68";
export var socialProof__container = "styles-module--social-proof__container--32b51";
export var socialProof__icon = "styles-module--social-proof__icon--1dbc7";
export var socialProof__label = "styles-module--social-proof__label--ce5d2";
export var strikethrough = "styles-module--strikethrough--65c29";
export var strikethroughBold = "styles-module--strikethrough--bold--47c50";
export var strikethroughLg = "styles-module--strikethrough--lg--ba2bd";
export var strikethroughSm = "styles-module--strikethrough--sm--04bfa";
export var subtext = "styles-module--subtext--9f800";
export var subtextBold = "styles-module--subtext--bold--3f9e7";
export var subtextUc = "styles-module--subtext--uc--d443d";
export var text = "styles-module--text--2e5bc";
export var textBold = "styles-module--text--bold--a2dd0";
export var textUc = "styles-module--text--uc--715bc";
export var titleL = "styles-module--title-l--7a92a";
export var titleM = "styles-module--title-m--98355";
export var titleS = "styles-module--title-s--da9d3";
export var titleXl = "styles-module--title-xl--ddfdd";
export var titleXs = "styles-module--title-xs--ab96d";
export var titleXxs = "styles-module--title-xxs--4cc87";
export var uc = "styles-module--uc--f449b";
export var underline = "styles-module--underline--83f05";