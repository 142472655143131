import { useMemo } from "react";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { ComponentType } from "../../constants/ComponentType";

import "./productReviewRating/styles.scss";

function ProductReviewRating({ type, onClick, productId }) {
  const formattedProductId = useMemo(
    () => productId?.replace("gid://shopify/Product/", ""),
    [productId]
  );

  if (!formattedProductId) {
    return null;
  }
  return (
    <div
      className={`pdp_details_reviews
      ${type === ComponentType.REVIEWS ? "pdp_details_reviews_list" : ""}
        ${type === ComponentType.PRODUCT_CAROUSEL ? "product_carousel_details_reviews" : ""}`}
      onClick={onClick}
      data-testid="reviews-rating-block"
    >
      <div data-bv-show="rating_summary" data-bv-product-id={formattedProductId} />
    </div>
  );
}

ProductReviewRating.propTypes = {
  type: PropTypes.oneOf(Object.values(ComponentType)),
  onClick: PropTypes.func,
  productId: PropTypes.string,
};

export default withALErrorBoundary({
  name: "ProductReviewRating",
  priority: "P2",
})(ProductReviewRating);
