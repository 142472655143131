import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { createCookie, createTimeCookie, getCookie, isBrowser } from "../context/helpers";
import { countdown } from "../hooks/useTimer";

import DefaultTimer from "./Timer/DefaultTimer";
import DateTimer from "./Timer/DateTimer";

function Timer({ timerStartDate, timerEndDate, timerCountdown }) {
  const TIMER_COOKIE = {
    expiration: 0,
    cookieName: "ab_timer",
  };

  const TIMER_TYPE = {
    cookieName: "ab_timer_type",
  };

  const [timerTypeUpdated, setTimerTypeUpdated] = useState(false);

  const [expiryTime, setExpiryTime] = useState(
    getCookie(TIMER_COOKIE.cookieName) ??
      createTimeCookie(TIMER_COOKIE.expiration, TIMER_COOKIE.cookieName)
  );
  const showDateTimer =
    countdown(timerStartDate, timerEndDate) && window.exponea.identified === "true";

  const onExpirationTime = () => {
    if (isBrowser) {
      let timeExpiration = 60;
      if (timerCountdown === "End of day") {
        const today = Date.now();
        const tomorrow = new Date().setHours(24, 0, 0, 0);
        timeExpiration = (tomorrow - today) / 1000 / 60;
      } else {
        timeExpiration = parseInt(timerCountdown, 10) * 60;
      }
      const cookie = createTimeCookie(timeExpiration, TIMER_COOKIE.cookieName);
      setExpiryTime(cookie);
      return cookie;
    }
  };

  useEffect(() => {
    if (isBrowser && timerCountdown) {
      if (timerCountdown !== getCookie(TIMER_TYPE.cookieName)) {
        onExpirationTime();
        createCookie(TIMER_TYPE.cookieName, timerCountdown, 3);
        setTimerTypeUpdated(true);
      }
    }
  }, [timerCountdown]);

  // If customer is a first time visitor, we will display the default timer (1hr/6hr/end of day)
  return showDateTimer ? (
    <DateTimer
      onExpirationTime={onExpirationTime}
      timerStartDate={timerStartDate}
      timerEndDate={timerEndDate}
    />
  ) : (
    <DefaultTimer
      expiryTime={expiryTime}
      onExpirationTime={onExpirationTime}
      timerTypeUpdated={timerTypeUpdated}
    />
  );
}

export default Timer;

Timer.propTypes = {
  timerCountdown: PropTypes.string,
  timerStartDate: PropTypes.string,
  timerEndDate: PropTypes.string,
};
